//-----Подвал сайта------------------------------------------------------------------------------//
.footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
        "news news media"
        "copy copy link";
    grid-template-rows: 1fr;
    width: 100%;
    position: relative;
}
//-----Блок новости------------------------------------------------------------------------------//
.footer-news {
    grid-area: news;
    background-color: $color-black;
    display: flex;
    justify-content: flex-start;
    padding: 72px 120px 125px 100px;
    margin: 0 10px 10px 0;
}
.footer-news-form {
    display: flex;
    flex-direction: column;
    gap: 17px;
    width: 100%;
}
.footer-news-form-label {
    color: $color-white;
    line-height: normal;
    font-weight: $font-weight-semi-bold;
    font-size: $font-special;
}
.footer-news-form-inputs-block {
    display: flex;
    align-items: center;
}
.footer-news-form-input-mail {
    outline: none;
    font-size: 15px;
    color: $color-black;
    height: 57px;
    width: 100%;
    padding: 0 20px;
}
.footer-news-form-input-mail::placeholder {
    color: $color-light-gray;
    font-size: 15px;
}
.footer-news-form-button {
    width: 120px;
    height: 57px;
    cursor: pointer;
    background-color: $color-mint;
    color: $color-black;
    font-weight: $font-weight-semi-bold;
    line-height: normal;
    font-size: $font-base-sx;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius:0;
}
.footer-news-form-button:hover {
    background-color: $color-red;
    color: $color-white;
    transition: 0.3s ease all;
}
.footer-news .footer-news-form .footer-news-form-inputs-block .webform-ajax-form-wrapper {
    display: flex;
    width: 100%;
}
.footer-news .footer-news-form .footer-news-form-inputs-block .webform-ajax-form-wrapper .webform-submission-form {
    display: flex;
    width: 100%;
}
.footer-news .footer-news-form .footer-news-form-inputs-block .webform-ajax-form-wrapper .webform-submission-form .form-type-email {
    width: 100%;
}
//-----Блок соц-сети------------------------------------------------------------------------------//
.footer-social-media {
    grid-area: media;
    background-color: $color-black;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    padding: 42px 100px 40px 92px;
    margin: 0 0 10px 0;
}
.footer-social-media-item {
    color: $color-white;
    font-size: $font-medium-x;
    line-height: normal;
    font-weight: $font-weight-semi-bold;
}
.footer-social-media-item:hover {
    color: $color-mint;
    transition: 0.3s ease all;
}
//-----Блок копирайт------------------------------------------------------------------------------//
.footer-copy {
    display: grid;
    grid-template-columns: minmax(220px, 400px) 1fr 1fr;
    grid-template-areas:
        "copy copy link";
    grid-template-rows: 125px;
    width: 100%;
}
.footer-copyright-logo-name {
    display: flex;
    gap: 30px;
    align-items: center;
    grid-area: copy;
    background-color: $color-black;
    justify-content: flex-start;
    padding: 0 0 0 60px;
}
.footer-copyright-logo-name__logo {
    width: 44px;
    height: 52px;
    //margin-left: auto;
    opacity: 0.7;
}
.footer-copyright-logo-name__spbu {
    display: flex;
    flex-direction: column;
    //margin-right: 50px;
    width: 843px;
}
.footer-copyright-logo-name__spbu p {
    font-size: $font-base-s;
    font-weight: $font-weight-light;
    line-height: normal;
    color: $color-light-gray;
}
.footer-copyright-link {
    grid-area: link;
    background-color: #1D1E25;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    padding: 42px 10px 40px 92px;
}
.footer-copyright-link p {
    line-height: normal;
    font-size: 20px;
    font-weight: 300;
    color: #BDBDBD;
    display: contents;
    white-space: nowrap;
}
.footer-copyright-link a {
    line-height: normal;
    font-size: 20px;
    font-weight: 300;
    color: #BDBDBD;
}
.footer-copyright-link a:hover {
    color: $color-mint;
    transition: 0.3s ease all;
}
//-----Блок навигатор------------------------------------------------------------------------------//
.footer-navigator {
    display: grid;
    grid-area: navigator;
    grid-template-columns: 78px 1fr;
    grid-template-rows: 459px;
    -webkit-transition-duration: 0.7s; /* анимационное выдвижение для всех браузеров*/
    -moz-transition-duration: 0.7s;
    -o-transition-duration: 0.7s;
    transition-duration: 0.7s;
}
.footer-navigator.active {
    width: fit-content;
    z-index: 4;
    opacity: 1;
    pointer-events: all;
    transform: translate(-80%);
    -webkit-transition-duration: 0.7s; /* анимационное выдвижение для всех браузеров*/
    -moz-transition-duration: 0.7s;
    -o-transition-duration: 0.7s;
    transition-duration: 0.7s;
}
.footer-navigator-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-white;
    font-size: $font-base-x;
    font-weight: $font-weight-semi-bold;
    background-color: $color-mint;
    white-space: nowrap;
    line-height: 133%;
    writing-mode: vertical-rl;
    transform: scale(-1);
    cursor: pointer;
    width: 100%;
    height: auto;
}
.footer-navigator-content {
    display: grid;
    grid-template-columns: 451px;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        "sections-title sections-links"
        "menu sections-links";
    padding-right: 12px;
}
.footer-navigator-content-sections-title-wrap {
    grid-area: sections-title;
    display: flex;
    flex-direction: column;
}
.footer-navigator-content-sections-title-wrap .footer-navigator-content-sections-title-link:first-child {
    padding-top: 25px;
}
.footer-navigator-content-sections-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color-smoke;
    font-size: $font-small-sx;
    color: $color-black;
    font-weight: $font-weight-semi-bold;
    cursor: pointer;
    padding: 7px 25px 7px 25px;
    &:hover {
        color: $color-mint;
        transition: 0.3s ease all;
    }
}
.footer-navigator-content-sections-title-link {
    display: flex;
    align-items: center;
    background-color: $color-white;
    font-size: $font-small-sx;
    color: $color-black;
    font-weight: $font-weight-semi-bold;
    cursor: pointer;
    padding: 7px 25px 7px 25px;
    &:hover {
        color: $color-mint;
        transition: 0.3s ease all;
    }
}
.footer-navigator-content-sections-title.active {
    color: $color-mint;
}
.footer-navigator-content-sections-title-img {

}
.footer-navigator-content-sections {
    display: none;
    flex-direction: column;
    gap: 8px;
    grid-area: sections-links;
    background-color: $color-smoke;
    padding: 25px 25px;
    position: absolute;
    right: 14px;
    height: 100%;
    width: 451px;
}
.footer-navigator-content-sections__item {
    color: $color-black;
    font-size: $font-small-sx;
    font-weight: $font-weight-semi-bold;
    line-height: 133%;
    &:hover {
        color: $color-mint;
        transition: 0.3s ease all;
    }
}
.footer-navigator-content-menu-links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    grid-area: menu;
    background-color: $color-white;
    padding: 6px 25px 25px 25px;
    height: 100%;
}
.footer-navigator-content-menu-links__item {
    color: $color-black;
    font-size: $font-small-sx;
    font-weight: $font-weight-semi-bold;
    line-height: 133%;
    &:hover {
        color: $color-mint;
        transition: 0.3s ease all;
    }
}
//------Адаптивная версия-------------------------------------------------------------------------//
@media (max-width: $adaptive-first-device) {
    .footer-copyright-logo-name {
        justify-content: flex-start;
        padding: 20px 0 0 60px;
        gap: 30px;
    }
    .footer-copyright-logo-name__spbu p {
        font-size: $font-base;
    }
    .footer-copyright-link p {
        font-size: $font-base;
    }
    .footer-copyright-link a {
        font-size: $font-base;
    }
}
@media (max-width: $adaptive-second-device) {
    .footer {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .footer-navigator-content {
        grid-template-columns: 400px;
    }
    .footer-navigator-content-sections {
        width: 400px;
    }
    .footer-navigator-title {
        font-size: $font-base-m;
    }
    //.footer-navigator-content-sections-title {
    //    font-size: $font-base-sx;
    //}
    //.footer-navigator-content-menu-links__item {
    //    font-size: $font-base;
    //}
    //.footer-navigator-content-sections__item {
    //    font-size: $font-base;
    //}
    .footer-news-form {
        width: 100%;
    }
    .footer-social-media {
        padding: 50px 50px;
    }
    .footer-news-form-label {
        font-size: $font-medium-s;
    }
    .footer-social-media-item {
        font-size: $font-base-x;
    }
    .footer-copyright-logo-name__spbu p {
        font-size: $font-small-sx;
    }
    .footer-copyright-logo-name__spbu a {
        font-size: $font-small-sx;
        line-height: normal;
        font-weight: 300;
        color: #BDBDBD;
    }
    .footer-copyright-link {
        padding: 50px 50px;
    }
    .footer-copyright-link p {
        font-size: $font-small-sx;
    }
    .footer-copyright-link a {
        font-size: $font-small-sx;
    }
    .footer-copyright-logo-name__logo {
        width: 40px;
        height: 55px;
    }
    .footer-navigator {
        grid-template-columns: 68px 1fr;
    }
}
@media (max-width: $adaptive-mobile) {
    .footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
        "news news"
        "copy media"
        "copy media";
        grid-template-rows: auto auto auto;
        //gap: 10px;
        width: 100%;
    }
    .footer-news {
        justify-content: center;
        align-items: center;
        margin: 0 0 10px 0;
    }
    .footer-copyright-logo-name {
        align-items: flex-start;
        justify-content: flex-start;
        padding: 42px 15px 15px 20px;
        gap: 22px;
        margin-right: 10px;
    }
    .footer-news-form-label {
        font-size: $font-base-sx;
    }
    .footer-news-form-input-mail {
        font-size: 15px;
        height: 43px;
        padding: 0 20px;
    }
    .footer-news-form-button {
        width: 64px;
        height: 43px;
        font-size: $font-small-s;
    }
    .footer-copyright-logo-name__spbu {
        margin-right: 0;
        width: 100%;
    }
    .footer-copyright-logo-name__logo {
        width: 35px;
        height: 41px;
        margin-top: 11px;
    }
    .footer-copyright {
        flex-direction: column;
        gap: 12px;
        padding: 37px 23px 43px 20px;
        align-items: flex-start;
    }
    .footer-copyright-link {
        display: none;
    }
    .footer-copyright__link {
        margin-top: 16px;
        font-family: 'Raleway';
        font-weight: $font-weight-light;
        color: $color-gray;
        font-size: $font-small-sx;
    }
    .footer-copyright__link p {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
    .footer-social-media {
        margin: 0;
        padding: 42px 20px 43px 27px;
    }
    .footer-social-media-item {
        font-size: $font-base;
    }
    .footer-navigator-title {
        font-size: $font-base;
        writing-mode: horizontal-tb;
        transform: scale(1);
    }
    .footer-navigator {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        width: 100%;
        transition-duration: 0s;
        -webkit-transition-duration: 0s;
    }
    .footer-navigator.active {
        transform: translateY(-455px);
        width: 100vw;
        z-index: 66;
    }
    .footer-navigator-title {
        height: 51px;
    }
    .footer-navigator-content {
        height: 100%;
        width: 100%;
        display: flex;
        visibility: hidden;
        flex-direction: column;
        padding: 0;
    }
    .footer-navigator-content-sections {
        gap: 12px;
        padding: 12px 16px 12px 45px;
        background-color: $color-white;
        position: initial;
        width: 100%;
    }
    .footer-navigator-content-sections-title-img {
        transform: rotate(90deg);
    }
    .footer-navigator-content-sections-title.active .footer-navigator-content-sections-title-img {
        transform: rotate(-90deg);
    }
    .footer-navigator-content-sections__item {
        font-size: 12px;
    }
    .footer-navigator-content-sections-title-wrap .footer-navigator-content-sections-title-link:first-child {
        padding-top: 16px;
    }
    .footer-navigator-content-menu-links {
        gap: 10px;
        padding: 14px 20px;
    }
    .footer-navigator-content-sections-title {
        padding: 7px 20px 7px 20px;
    }
    .footer-navigator-content-sections-title-link {
        padding: 7px 20px 7px 20px;
    }
}
@media (max-width: $adaptive-mobile-small) {
    .footer {
        grid-template-columns: 60% 40%;
    }
    .footer-news {
        justify-content: center;
        align-items: center;
        padding: 37px 20px 49px 20px;
    }
    .footer-navigator-title {
        font-size: $font-small-s;
    }
    //.footer-navigator-content-sections-title {
    //    font-size: $font-small-s;
    //    padding: 16px 16px 16px 24px;
    //}
    //.footer-navigator-content-menu-links__item {
    //    font-size: 13px;
    //}
    //.footer-navigator-content-sections__item {
    //    font-size: 13px;
    //}
    //.footer-navigator.active {
    //    transform: translateY(-275px);
    //}
}
@media (max-width: $adaptive-mobile-small-xs) {
    .footer-copyright-logo-name__spbu p {
        font-size: $font-small;
    }
    .footer-copyright__link {
        font-size: $font-small;
    }
    .footer-copyright__link a {
        font-size: $font-small;
    }
}