//-----Контейнер для видео-контента---------------------------------------------------------//
.page-headline-video {
  display: flex;
  flex-direction: row;
  position: relative;
  pointer-events: none;
}
.page-headline-video:after {
  display: table;
  clear: both;
  content: ' ';
}
.page-headline__media-video {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}
.page-headline__media-video:before {
  position: relative;
  display: block;
  height: 0;
  padding-top: 56.25%;
  content: '';
}
.page-headline__media-video .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

