//-----Контейнер для меню-навигации("Главная страница")---------------------------------------------------------//
  .nav-menu-main-block {
    max-width: 2050px;
    margin: 0 auto;
    box-sizing: border-box;
    width: 100%;
  }
  .nav-menu-main-ul {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
  }
  .nav-menu-main-li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 100%;
    min-height: 136px;
    cursor: pointer;
  }
  .nav-menu-main-li--item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 100%;
    min-height: 136px;
    cursor: pointer;
  }
  .nav-menu-main-li--item > a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-decoration: none;
  }
  .nav-menu-main-li > a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-decoration: none;
    padding: 0 50px;
  }
  .nav-menu-main-li:hover {
    background-color: $color-red;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    & .nav-menu-main-text {
      display: initial;
    }
    & .nav-menu-main-link {
      display: none;
    }
  }
  .nav-menu-main-li--item:hover {
    background-color: $color-red;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    & .nav-menu-main-link {
      color: $color-white;
    }
  }
  .nav-menu-main-link {
    color: $color-black;
    font-weight: $font-weight-semi-bold;
    font-size: $font-special-s;
    display: initial;
    cursor: pointer;
  }
  .nav-menu-main-text {
    display: none;
    line-height: 106%;
    font-size: $font-base;
    font-weight: $font-weight-semi-bold;
    color: $color-white;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;
  }

@media (max-width: $adaptive-ipad) {
  .nav-menu-main-ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 18px;
    grid-row-gap: 40px;
    padding: 22px 18px 40px 18px;
  }
  .nav-menu-main-li {
    flex-direction: column;
    gap: 20px;
    width: 100%;
    min-height: 100%;
    align-items: center;
  }
  .nav-menu-main-li--item {
    flex-direction: column;
    width: 100%;
    min-height: 110px;
    align-items: center;
    justify-content: center;
  }
  .nav-menu-main-li > a {
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
  }
  .nav-menu-main-li:hover {
    background-color: $color-white;
    & .nav-menu-main-text {
      display: initial;
    }
    & .nav-menu-main-link {
      display: initial;
    }
  }
  .nav-menu-main-li--item:hover {
    background-color: $color-white;
    & .nav-menu-main-link {
      color: $color-black;
    }
  }
  .nav-menu-main-text {
    display: initial;
    color: $color-darkgray;
    line-height: 106%;
  }
  .nav-menu-main-link {
    font-size: $font-medium-s;
  }
  .nav-menu-main-text {
    font-size: $font-small-sx;
  }
  .nav-menu-main-li > a {
    padding: 0;
  }
}
@media (max-width: $adaptive-mobile-small-xs) {
  .nav-menu-main-li--item {
    min-height: 90px;
  }
  .nav-menu-main-text {
    display: initial;
    color: $color-darkgray;
    line-height: 106%;
    font-size: $font-small-x;
  }
  .nav-menu-main-link {
    font-size: $font-base-m;
  }
}
@media (max-width: $adaptive-mobile-ultra-small) {
  .nav-menu-main-ul {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}
//-----Контейнер для меню новости-события---------------------------------------------------------//
.link-news-events-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 46px 0 46px 0;
  background-color: $color-mint;
}
.link-news-events-wrap:hover {
  background-color: $color-red;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.link-news-events-block {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 6px solid $color-white;
  margin-right: 24px;
  padding-right: 24px;
}
.link-news-events-item {
  line-height: 103%;
  font-weight: $font-weight-extra-bold;
  color: $color-white;
  font-size: $font-large-x;
}
.link-news-events-wrap div:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
}

@media (max-width: $adaptive-second-device) {
  .link-news-events-item {
    font-size: $font-large;
  }
}
@media (max-width: $adaptive-mobile) {
  .link-news-events-item {
    font-size: $font-special;
  }
  .link-news-events-block {
    border-right: 2px solid $color-white;
    margin-right: 14px;
    padding-right: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .link-news-events-wrap {
    padding: 13px 0 13px 0;
  }
}
@media (max-width: $adaptive-mobile-small) {
  .link-news-events-item {
    font-size: $font-medium;
  }
}
@media (max-width: $adaptive-mobile-small-xs) {
  .link-news-events-item {
    font-size: $font-base-sx;
  }
}
//-----Контейнер для блока с табами---------------------------------------------------------------------------------//
.menu-description-wrap {
  //display: grid;
  //grid-auto-rows: 1fr;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.menu-description-wrap > .menu-description-content-wrap:last-child > .menu-description-content {
  margin-bottom: 0;
}
.menu-description-wrap > .menu-description-content-wrap:last-child {
  border-bottom: none;
}
.menu-description-content-wrap {
  display: none;
}
.menu-description-content-wrap._active {
  display: block;
  border-bottom: 2px solid $color-smoke;
}
.menu-description-tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 120px;
}
.tabs__shift-right {
  display: grid;
  grid-template-columns: 60% 40%;
}
.tabs__shift-left {
  display: grid;
  grid-template-columns: 40% 60%;
}
.menu-description-tabs > div:last-child {
  border-right: none;
}
.menu-description-content__close-bt {
  display: none;
}
.menu-description-tabs__block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 0 50px;
  border-bottom: 2px solid $color-smoke;
  border-right: 2px solid $color-smoke;
  cursor: pointer;
}
.menu-description-tabs__block._active {
  background-color: $color-red;
  //border-top: 2px solid $color-red;
  border-bottom: 2px solid $color-red;
  border-right: 2px solid $color-red;
  & .menu-description-tabs__item {
    color: $color-white;
  }
}
.menu-description-tabs__block:hover {
  background-color: $color-red;
  //border-top: 2px solid $color-red;
  border-bottom: 2px solid $color-red;
  border-right: 2px solid $color-red;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  & .menu-description-tabs__item {
    color: $color-white;
  }
}
.menu-description-tabs__item {
  font-size: $font-medium-s;
  line-height: 103%;
  font-weight: $font-weight-semi-bold;
  text-align: center;
  color: $color-black;
  word-break: normal;
}
.menu-description-content {
  display: none;
  flex-direction: column;
  max-width: 2050px;
  margin: 0 auto 85px auto;
  box-sizing: border-box;
  width: 100%;
}
.menu-description-content__block {
  display: flex;
  flex-direction: column;
  padding: 100px 15px 15px 65px;
  margin: 0 50px 0 50px;
}
.menu-description-content.active {
  display: flex;
}
.menu-description-content__title {
  line-height: 103%;
  font-weight: $font-weight-semi-bold;
  color: $color-black;
  font-size: $font-large;
  margin-bottom: 4.7%;
}
.menu-description-content__desc {
  color: $color-black;
  line-height: 113%;
  font-weight: $font-weight-light;
  font-size: $font-base-x;
  margin-bottom: 5%;
}
.menu-description-content__bt {
  background-color: $color-smoke;
  color: $color-darkgray;
  line-height: 103%;
  font-weight: $font-weight-semi-bold;
  font-size: $font-base-x;
  padding: 18px 0 18px 32px;
  width: 100vw;
}
.menu-description-content__bt:hover {
  background-color: $color-red;
  color: $color-white;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
@media (max-width: $adaptive-second-device) {
  .menu-description-tabs__block {
    padding: 20px 50px;
  }
  .menu-description-tabs__item {
    font-size: $font-base-x;
  }
}
@media (max-width: $adaptive-mobile) {
  .menu-description-wrap {
    display: initial;
  }
  .menu-description-tabs {
    flex-direction: column;
    margin: 0;
    border: none;
    height: auto;
  }
  .menu-description-tabs__block {
    padding:  32px 16px;
    border-right: none;
    border-bottom: 2px solid $color-smoke;
    border-top: none;
  }
  .menu-description-tabs__block._active {
    border-right: 0;
    border-top: 2px solid $color-red;
    border-bottom: 2px solid $color-red;
  }
  .menu-description-tabs__item {
    line-height: 103%;
    font-size: $font-medium-sm;
  }
  .menu-description-content__close-bt {
    display: initial;
    width: 35px;
    height: 35px;
    align-self: flex-end;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .menu-description-content__block {
    padding: 35px 35px;
    margin: 0;
  }
  .menu-description-content {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
    background-color: $color-white;
    box-shadow: 0 8px 22px rgba(188, 188, 188, 0.25);
    border-radius: 0.22em;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 56;
    margin: 0;
  }
  .menu-description-content__title {
    margin-bottom: 48px;
    line-height: 103%;
    font-size: $font-medium-sm;
  }
  .menu-description-content__desc {
    line-height: 133%;
    font-size: $font-small-sx;
    margin-bottom: 0;
  }
  .menu-description-content__bt {
    display: none;
  }
  .tabs__shift-right {
    display: inherit;
  }
  .tabs__shift-left {
    display: inherit;
  }
}
@media (max-width: $adaptive-mobile-small) {
  .menu-description-tabs__item {
    font-size: $font-base;
  }
}
//-----Контейнер для меню-ссылки-блоки-----------------------------------------------------------------------------//
.menu-link-blocks-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid: 420px 60px 420px / 1fr 1fr 1fr;
  grid-template-areas: "item1 item2 item3"
                        "item1 item5 item3"
                        "item4 item5 item6";

  margin: 0 auto 0 50px;
}
.menu-link-blocks__block:nth-child(1) {
  grid-area: item1;
}
.menu-link-blocks__block:nth-child(2) {
  grid-area: item2;
}
.menu-link-blocks__block:nth-child(3) {
  grid-area: item3;
}
.menu-link-blocks__block:nth-child(4) {
  grid-area: item4;
}
.menu-link-blocks__block:nth-child(5) {
  grid-area: item5;
}
.menu-link-blocks__block:nth-child(6) {
  grid-area: item6;
}
.menu-link-blocks__block {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;
  padding: 40px 45px 40px 45px;
  word-break: normal;
  position: relative;

}
.menu-link-blocks-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: brightness(65%)
}
.menu-link-blocks__block:hover {
  background-color: $color-red;
  transition: 0.6s;
  & .menu-link-blocks__name-block-default {
    display: none;
  }
  & .menu-link-blocks__name-block-show {
    display: block;
  }
  & .menu-link-blocks-image {
    visibility: hidden;
    opacity: 0;
    transition: visibility ease 0s, opacity ease 1s;
  }
}
.menu-link-blocks__name-block-default {
  display: initial;
  color: $color-white;
  font-size: $font-special-x;
  font-weight: $font-weight-bold;
  letter-spacing: 0.235px;
  text-overflow: ellipsis;
  word-break: normal;
  z-index: 3;
}
.menu-link-blocks__name-block-show {
  color: $color-white;
  font-size: $font-base-x;
  font-weight: $font-weight-semi-bold;
  line-height: 122%;
  text-overflow: ellipsis;
  word-break: normal;
  display: none;
}
@media (max-width: $adaptive-special-device) {
  .menu-link-blocks__block {
    padding: 30px 35px;
  }
  .menu-link-blocks__name-block-default {
    font-size: $font-medium-sm;
    word-break: break-word;
  }
  .menu-link-blocks__name-block-show {
    font-size: $font-base-s;
  }
}
@media (max-width: $adaptive-second-device) {
  .menu-link-blocks-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(320px, 380px);
  }
  .menu-link-blocks__block {
    padding: 20px 20px;
  }
  .menu-link-blocks__name-block-default {
    font-size: $font-base-sx;
    word-break: break-word;
  }
  .menu-link-blocks__name-block-show {
    font-size: $font-base;
    word-break: break-word;
  }
}
@media (max-width: $adaptive-mobile) {
  .menu-link-blocks-wrap {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .menu-link-blocks-wrap .menu-link-blocks__block:first-child {
    padding: 71px 20px 20px 16px;
  }
  .menu-link-blocks__name-block-show {
    display: block;
    font-size: $font-base-s;
  }
  .block-color--black {
    background-color: $color-black;
  }
  .block-color--mint {
    background-color: $color-mint;
  }
  .block-color--gray {
    background-color: $color-darkgray;
  }
  .menu-link-blocks__block {
    padding: 16px 20px 20px 16px;
    gap: 8px;
    align-items: flex-start;
  }
  .menu-link-blocks-image {
    display: none;
  }
  .menu-link-blocks__block:hover {
    & .menu-link-blocks__name-block-default {
      display: initial;
    }
    & .menu-link-blocks__name-block-show {
      display: initial;
    }
  }

}
@media (max-width: $adaptive-mobile-small) {
  .menu-link-blocks__name-block-default {
    font-size: $font-medium-sm;
  }
  .menu-link-blocks__name-block-show {
    font-size: $font-base-s;
  }
}
@media (max-width: $adaptive-mobile-small-xs) {
  .menu-link-blocks__name-block-default {
    font-size: $font-base;
    line-height: 100%;
    letter-spacing: 0.07px;
  }
  .menu-link-blocks__name-block-show {
    font-size: $font-small;
    line-height: 103%;
    font-weight: $font-weight-light-bold;
  }
}