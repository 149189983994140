//-----Основной контейнер(слайдер на главной странице)-------------------------------------------//
.slider-container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.slider {
  display: flex;
  position: relative;
  height: 470px;
}
.slider-main-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  opacity: 0;
}
.slider-image--brightness {
  filter: brightness(50%)
}
.slider-main-image._active {
  display: block;
  opacity: 1;
  transition: all 0.6s ease;
}
.slider-controls {
  position: absolute;
  width: 100%;
  padding: 0 50px 0 20px;
}
.slider-controls-direction {
  position: relative;
  max-width: 1950px;
  margin: 0 auto;
  z-index: 10;
  top: 295px;
}
.prev-button {
  position: relative;
  float: left;
  width: 25px;
  height: 39px;
  outline: none;
  background-repeat: no-repeat;
  background-image: url(../../img/icons/SliderArrowLeft.svg);
  cursor: pointer;
  background-size: contain;
}
.next-button {
  position: absolute;
  right: 0;
  float: left;
  width: 25px;
  height: 39px;
  outline: none;
  background-repeat: no-repeat;
  background-image: url(../../img/icons/SliderArrowRight.svg);
  cursor: pointer;
  background-size: contain;
}
.prev-button::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 17px;
  height: 28px;
  margin-top: -13px;
  margin-left: -8px;
  background-repeat: no-repeat;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  content: "";
  cursor: pointer;
}
.next-button::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 17px;
  height: 28px;
  margin-top: -13px;
  margin-left: -8px;
  background-repeat: no-repeat;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  content: "";
}
.slider-content {
  position: absolute;
  width: 100%;
  padding: 0 170px 0 100px;
}
.slider-content-container {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1750px;
  margin: 0 auto;
  z-index: 8;
  top: 250px;
  gap: 4px;
}
.slider-content-container a:hover {
  color: $color-white;
  opacity: 0.6;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.3s;
}
.slider-content-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.slider-content-menu span {
  color: $color-white;
  height: 32px;
  font-weight: $font-weight-light;
  font-size: $font-medium-s;
  border-left: 2px solid $color-white;
  margin-left: 8px;
  padding-left: 8px;
}
.slider-content-menu span:first-child {
  border: none;
  margin-left: 0;
  padding-left: 0;
}
.slider-content-title {
  letter-spacing: 0.435px;
  font-size: $font-large-s;
  color: $color-white;
  font-weight: $font-weight-extra-bold;
}
//------Адаптивная версия(слайдер на главной странице)--------------------------------------------------------------------------//
@media (max-width: $adaptive-second-device) {
  .slider-content-menu span {
    color: #FFFFFF;
    height: 30px;
    font-size: $font-base-x;
  }
  .slider-content-title {
    font-size: $font-large;
  }
  .prev-button,
  .next-button {
    width: 17px;
    height: 25px;
  }
}
@media (max-width: $adaptive-mobile) {
  .slider {
    height: 461px;
  }
  .slider-controls-direction {
    top: 420px;
    margin: 0 auto;
  }
  .slider-controls {
    padding: 0 16px 0 16px;
  }
  .slider-controls-direction::after {
    position: absolute;
    top: 50%;
    right: 5px;
    height: 54px;
    margin-top: -13px;
    margin-right: -20px;
    content: "";
    z-index: -4;
    width: 50%;
  }
  .slider-content {
    padding: 0 16px 0 16px;
  }
  .slider-content-menu span {
    color: #FFFFFF;
    height: 23px;
    font-size: $font-base-s;
  }
  .slider-content-title {
    font-size: $font-special-x;
    line-height: 100%;
    letter-spacing: 0.235px;
  }
  .slider-content-container {
    gap: 8px;
    top: 280px;
  }
}
//-----Слайдер с текстом-------------------------------------------------------------------//
.slider-page-container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.slider-page {
  display: flex;
  position: relative;
  height: 470px;
}
.slider-page-content-wrap {
  display: flex;
  flex-direction: row;
  position: relative;
  max-width: 1950px;
  margin: 0 auto;
  align-items: center;
  top: 300px;
}
.slider-page-main-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  filter: brightness(40%)
}
.slider-page-controls {
  position: absolute;
  width: 100%;
}
.slider-page-controls-direction {
  position: relative;
  max-width: 1950px;
  margin: 0 auto;
  z-index: 10;
}
.prev-button {
  position: relative;
  float: left;
  width: 25px;
  height: 39px;
  outline: none;
  background-repeat: no-repeat;
  background-image: url(../../img/icons/SliderArrowLeft.svg);
  cursor: pointer;
  background-size: contain;
}
.next-button {
  position: absolute;
  right: 0;
  float: left;
  width: 25px;
  height: 39px;
  outline: none;
  background-repeat: no-repeat;
  background-image: url(../../img/icons/SliderArrowRight.svg);
  cursor: pointer;
  background-size: contain;
}
.prev-button::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 17px;
  height: 28px;
  margin-top: -13px;
  margin-left: -8px;
  background-repeat: no-repeat;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  content: "";
  cursor: pointer;
}
.next-button::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 17px;
  height: 28px;
  margin-top: -13px;
  margin-left: -8px;
  background-repeat: no-repeat;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  content: "";
}
.slider-page-content {
  position: absolute;
  width: 100%;
  padding: 0 50px 0 70px;
}
.slider-page-content-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  max-width: 1950px;
  z-index: 8;
  padding: 0 50px 0 80px;
}
.slider-page-content-title {
  line-height: normal;
  font-size: $font-medium-s;
  color: $color-white;
  font-weight: $font-weight-semi-bold;
  display: none;
}
.slider-page-content-title._active {
  display: block;
  opacity: 0;
  transition: 1s;
  animation-delay: 1s;
  animation: slidein 1s 1;
  animation-fill-mode: forwards;
}
@keyframes slidein {
  0% {
  opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: $adaptive-second-device) {
  .prev-button,
  .next-button {
    width: 20px;
    height: 25px;
  }
}
@media (max-width: $adaptive-mobile) {
  .slider-page {
    height: 461px;
  }
  .slider-page-controls-direction {
    top: 420px;
    margin: 0 auto;
  }
  .slider-page-controls {
    padding: 0;
    bottom: 255px;
  }
  .slider-page-controls-direction::after {
    position: absolute;
    top: 50%;
    right: 5px;
    height: 54px;
    margin-top: -13px;
    margin-right: -20px;
    content: "";
    z-index: -4;
    width: 50%;
  }
  .slider-page-content {
    padding: 0 16px 0 16px;
  }
  .slider-page-content-title {
    font-size: $font-base-m;
    letter-spacing: 0.235px;
  }
  .slider-page-content-wrap {
    top: 280px;
  }
  .slider-page-content-container {
    padding: 0;
  }
}
@media (max-width: $adaptive-mobile-small) {
  .slider-content-title {
    font-size: $font-special-s;
  }
  .slider-content-menu {
    white-space: nowrap;
  }
  .slider-content-menu span {
    color: #FFFFFF;
    height: 16px;
    font-size: $font-small-s;
  }
}
@media (max-width: $adaptive-mobile-small-xs) {
  .prev-button,
  .next-button {
    width: 17px;
    height: 25px;
  }
  .slider-page-content-title {
    font-size: $font-small-s;
    word-wrap: normal;
  }
  .slider-content-title {
    font-size: $font-special-s;
    word-break: break-word;
  }
}
@media (max-width: $adaptive-mobile-ultra-small) {
  .slider-content-title {
    font-size: $font-medium-sm;
  }
  .slider-page-content-title {
    font-size: $font-small;
  }
  .slider-content-menu span {
    height: 12px;
    font-size: 12px;
  }
}
@media (max-width: $adaptive-large-device) {
  .slider--with-sidebar {
    margin: 0 auto 0 50px;
  }
}
@media (max-width: $adaptive-mobile) {
  .slider--with-sidebar {
    margin: 0 auto;
  }
}
