//@function calc-between-width($width-start, $value-start, $width-end, $value-end) {
//  @return calc(#{$value-start} * 1px + (#{$value-end} - #{$value-start}) * (100vw - #{$width-start} * 1px) / (#{$width-end} - #{$width-start}))
//}
//
//$vw-viewport: 14400;
//@function get-vw($item) {
//  $vw-context: $vw-viewport * 0.01 * 1px;
//  @return calc($item / $vw-context * 1vw);
//  @return $item;
//}
//
//$vh-viewport: 1350;
//@function get-vh($item) {
//  $vh-context: $vh-viewport * 0.01 * 1px;
//  @return calc($item / $vh-context * 1vh);
//  @return $item;
//}

//-----Wrapper------------------------------------------------------------------------------------//
.wrapper {
  min-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: $color-white;
}
.main-block {
  display: flex;
  flex-direction: column;
  position: relative;
}
@media (max-width: 320px) {
  .wrapper {
    overflow: auto;
  }
}
//-----Divider--------------------------------------------------------------------------------------//
.divider-section {
  margin-top: 24px;
  margin-bottom: 45px;
  height: 2px;
  background-color: $color-smoke;
}
//-----Sections--------------------------------------------------------------------------------------//
.section-indent--large {
  margin-top: 100px;
  margin-bottom: 100px;
}
.section-indent--medium {
  margin-top: 75px;
  margin-bottom: 100px;
}
.section-indent--mr-bottom {
  margin-bottom: 100px;
}
.g-container {
  margin: 0 50px 0 50px;
  padding-right: 15px;
  padding-left: 65px;
}
.g-container-gap {
  display: flex;
  flex-direction: column;
  gap: 56px;
}
@media (max-width: $adaptive-mobile) {
  .section-indent--large {
    margin-top: 75px;
    margin-bottom: 75px;
  }
  .section-indent--medium {
    margin-top: 55px;
    margin-bottom: 75px;
  }
  .section-indent--mr-bottom {
    margin-bottom: 75px;
  }
}
@media (max-width: $adaptive-mobile-small) {
  .section-indent--large {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .section-indent--medium {
    margin-top: 40px;
    margin-bottom: 50px;
  }
}
@media (max-width: $adaptive-mobile) {
  .g-container {
    margin: 0 auto;
    padding-right: 20px;
    padding-left: 20px;
  }
}
//-----Grids--------------------------------------------------------------------------------------//
.pure-g > div {
  box-sizing: border-box;
}
//.pure-g > div:last-child {
//  padding-right: 0;
//}
.pure-grid--margin-bottom-sm {
  margin-bottom: 40px;
}
.pure-grid--padding-right {
  padding-right: 48px;
}
.pure-grid--padding-right-sm {
  padding-right: 24px;
}
.pure-grid--padding-sm {
  padding: 30px 30px;
}
.pure-grid--space-around {
  justify-content: space-around;
}
.pure-grid--gap-medium {
  gap: 40px;
}
@media (max-width: $adaptive-second-device) {
  .pure-grid--padding-right {
    padding-right: 28px;
  }
  .pure-grid--padding-right-sm {
    padding-right: 18px;
  }
}
@media (max-width: $adaptive-mobile) {
  .pure-grid--margin-bottom-sm {
    margin-bottom: 20px;
  }
}
//-----Hide-show--------------------------------------------------------------------------------------//
.g-hide {
  display: none;
}
.g-show-sm {
  display: none;
}
.g-hide-sx {
  display: none;
}
.g-show-medium {
  display: none;
}
@media (max-width: $adaptive-ipad) {
  .g-show-medium {
    display: initial;
  }
  .g-hide-medium {
    display: none;
  }
}
@media (max-width: $adaptive-mobile) {
  .g-hide-sx {
    display: initial;
  }
  .g-hide-sm {
    display: none;
  }
  .g-show-sm {
    display: initial;
  }
}
//-----Error-page--------------------------------------------------------------------------------------//
.error-section-wrap {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 470px;
  background-image: url("../../img/ErrorImage.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.error-section__title {
  margin: 0 auto;
  padding: 0 50px 50px 100px;
  box-sizing: border-box;
  letter-spacing: 0.35px;
  line-height: normal;
  font-size: $font-large;
  font-weight: $font-weight-extra-bold;
  color: $color-white;
}
.error-section__ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.error-section__li {
  padding: 42px 58px;
}
.error-section_item {
  color: $color-black;
  font-weight: $font-weight-semi-bold;
  font-size: $font-medium-s;
}

@media (max-width: $adaptive-second-device) {
  .error-section__title {
    font-size: $font-special-x;
  }
  .error-section__li {
    padding: 25px 25px;
  }
  .error-section_item {
    font-size: $font-base-x;
  }
}
@media (max-width: $adaptive-mobile) {
  .error-section__ul {
    flex-direction: column;
  }
  .error-section-wrap {
    height: 461px;
  }
  .error-section_item {
    font-size: $font-base-m;
  }
}
@media (max-width: $adaptive-mobile-small) {
  .error-section__title {
    font-size: $font-medium-x;
    padding: 25px 25px;
  }
  .error-section_item {
    font-size: $font-base-m;
  }
}
//-----Поиск------------------------------------------------------------------------------------//
.search-wrap-modal {
  width: 100%;
  display: none;
  position: absolute;
  z-index: 33;
}
.search-container-modal {
  position: fixed;
  display: flex;
  height: 470px;
  background-image: url("../../img/SeacrhModalImage.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  max-width: 2050px;
}
.search-wrap {
  width: 100%;
  display: block;
}
.search-container {
  position: relative;
  display: flex;
  height: 358px;
  background-image: url("../../img/SeacrhModalImage.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}
.search-form-modal {
  display: flex;
  flex-direction: column;
  padding: 283px 50px 0 60px;
  position: relative;
  width: 1544px;
  margin: 0 auto;
}
.search-form {
  display: flex;
  flex-direction: column;
  padding: 154px 50px 0 60px;
  position: relative;
  width: 1544px;
  margin: 0 auto;
}
.search-form__input {
  display: block;
  width: 100%;
  padding: 0 160px 6px 0;
  font-size: $font-large-sm;
  font-weight: $font-weight-extra-bold;
  line-height: normal;
  color: $color-white;
  border: none;
  border-bottom: 8px solid $color-white;
  background-color: transparent;
  outline: none;
  letter-spacing: 0.3px;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
   border-radius:0;
}
.search-form__button {
  cursor: pointer;
  position: absolute;
  right: 50px;
  margin-top: 25px;
  color: $color-white;
  font-size: $font-special;
  font-weight: $font-weight-extra-bold;
  letter-spacing: 0.2px;
}
.search-form__close-icon {
  position: absolute;
  cursor: pointer;
  width: 53px;
  height: auto;
  right: 50px;
  top: 150px;
}
.search-form__input::placeholder {
  color: rgba(255, 255, 255, 0.40);
}
.search-content-wrap {
  display: flex;
  background-color: $color-white;
  width: 100%;
}
.search-content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 1544px;
  margin: 0 auto;
  padding: 32px 60px 74px 60px;
}
.search-content__col-elem-block {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 51px;
}
.search-content__col-item {
    font-family: 'Raleway';
    color: $color-darkgray;
    font-size: $font-base-x;
    font-style: normal;
    font-weight: $font-weight-light;
    line-height: 103.023%;
}
.search-content-find-block {
  display: flex;
  flex-direction: column;
  padding: 0 50px 0 50px;
  gap: 34px;
}
.search-content-find__item {
  display: flex;
  flex-direction: column;
}
.search-content-find__item-title {
  color: #1D1E25;
  font-size: $font-special;
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  line-height: normal;
  margin-bottom: 34px;
}
.search-content-find__item-title:hover {
  color: $color-mint;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.search-content-find__item-text:hover {
  color: $color-mint;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.search-content-find__item-text {
  color: $color-black;
  font-family: 'Raleway';
  font-size: $font-base-x;
  font-style: normal;
  font-weight: $font-weight-light;
  line-height: 103.023%;
}
@media (max-width: $adaptive-mobile) {
  .search-container-modal {
    //position: fixed;
    height: 337px;
  }
  .search-container {
    height: 337px;
  }
  .search-form__input {
    padding: 0 140px 6px 0;
    font-size: $font-special-x;
  }
  .search-form-modal {
    padding: 160px 20px 0 20px;
  }
  .search-form {
    padding: 160px 20px 0 20px;
  }
  .search-form__button {
    right: 20px;
    margin-top: 15px;
    font-size: $font-medium-x;
  }
  .search-form__close-icon {
    right: 0;
    top: 80px;
  }
}
@media (max-width: $adaptive-mobile-small) {
  .search-form__input {
    font-size: $font-medium-s;
    padding: 0 0 6px 0;
  }
  .search-form__button {
    top: 236px;
    margin: 0;
    font-size: $font-base-x;
  }
  .search-form__close-icon {
    width: 32px;
    height: 32px;
    right: 8px;
  }
  .search-content {
    padding: 20px 20px 50px 20px;
  }
  .search-content-find-block {
    padding: 0 20px 0 20px;
  }
}
@media (max-width: $adaptive-mobile-small-xs) {
  .search-content-find__item-title {
    font-size: $font-medium-sm;
  }
  .search-content-find__item-text {
    font-size: $font-base;
  }
  .search-content__col-elem-block {
    margin-bottom: 30px;
  }
  .search-content__col-item {
    font-size: $font-small-s;
  }
}

//-----Навигация галереи страницы о нас------------------------------------------------------------------------------------//
.about-gallery .owl-nav {
  display: none;
}
.about-gallery .owl-carousel .owl-dots {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 18px;
  margin-top: 37px;
}
.about-gallery .owl-dots .owl-dot {
  height: 17px;
  width: 17px;
  background-color: $color-gray;
}
.about-gallery .owl-dots .owl-dot.active {
  height: 17px;
  width: 17px;
  background-color: $color-mint;
}
@media (max-width: $adaptive-mobile-small-xs) {
  .about-gallery .owl-carousel .owl-dots {
    gap: 10px;
    margin-top: 25px;
  }
  .about-gallery .owl-dots .owl-dot {
    height: 10px;
    width: 10px;
  }
  .about-gallery .owl-dots .owl-dot.active {
    height: 10px;
    width: 10px;
  }
}
//---Стили для текста---------------------------------------------------------------------------------------------------//
.editor-wrap {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  word-wrap: break-word;
}
.editor-wrap--large {
  position: relative;
  margin-top: 55px;
  margin-bottom: 55px;
  word-wrap: break-word;
}
.editor-wrap--small {
  margin-top: 20px;
  word-wrap: break-word;
}
.editor-wrap:first-child {
  margin-top: 0;
}
.editor-wrap:last-child {
  margin-bottom: 0;
}
.editor h2,
.editor h3 {
  margin-top: 45px;
  margin-bottom: 35px;
  font-family: "Montserrat";
  color: $color-black;
}
.editor h3 {
  font-weight: 600;
}
.editor h2 {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.3125;
}
.editor h3 {
  font-size: 26px;
  line-height: 1.3;
}
.editor p {
  font-family: 'Raleway';
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: $font-weight-light;
  font-size: $font-base-x;
  line-height: 123%;
  color: $color-black;
  letter-spacing: .3px;
}
.editor b,
.editor strong {
  font-weight: 600;
}
.editor small {
  font-size: $font-small-s;
  line-height: 123%;
}
.editor i,
.editor em {
  font-style: italic;
}
.editor a {
  color: inherit;
}
.editor a:active,
.editor a:hover {
  text-decoration: none;
  outline: 0;
}
.editor a:not([class]) {
  position: relative;
  color: $color-mint;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  cursor: pointer;
  text-decoration: underline;
}
.editor a:not([class]):hover {
  opacity: 0.6;
  -webkit-transition: color .7s ease;
  transition: color .7s ease;
}
.editor .bt-link {
  display: flex;
  background-color: $color-smoke;
  color: $color-darkgray;
  line-height: 103%;
  font-weight: $font-weight-semi-bold;
  font-size: $font-base-x;
  padding: 18px 0 18px 32px;
  font-family: "Montserrat";
  width: 100%;
}
.editor .bt-link:hover {
  background-color: $color-red;
  color: $color-white;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.editor ul,
.editor ol {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0 0 0 50px;
  counter-reset: item;
}
.editor ul,
.editor ol,
.editor ul p,
.editor ol p {
  font-size: $font-base-x;
  line-height: 123%;
  color: $color-black;
  letter-spacing: .3px;
}
.editor ul p,
.editor ol p {
  margin: 0;
}
.editor ul li {
  font-family: Raleway;
  position: relative;
  margin-bottom: 25px;
  padding-left: 20px;
  list-style-type: disc;
  font-weight: 300;
}
.editor ol > li {
  font-family: Raleway;
  position: relative;
  margin-bottom: 25px;
  padding-left: 20px;
  list-style: decimal;
  display: block;
  font-weight: 300;
}
.editor ol > li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}
.editor ul ul,
.editor ul ol {
  margin-top: 20px;
  margin-bottom: 20px;
}
.editor ol ul,
.editor ol ol {
  margin-top: 20px;
  margin-bottom: 20px;
}
.editor blockquote {
  border-left: 20px solid $color-mint;
  margin-top: 65px;
  margin-bottom: 65px;
}
.editor blockquote p {
  margin: 0 0 0 55px;
  font-size: 26px;
  line-height: 123%;
  color: $color-black;
  letter-spacing: 0;
}
.editor .quote--gradient {
  margin-top: 55px;
  margin-bottom: 55px;
  font-weight: 600;
}
.editor .quote--mint {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 40px 30px;
  border-top: 3px solid $color-mint;
  border-bottom: 3px solid $color-mint;
}
.editor .quote--mint p {
  font-size: 26px;
  line-height: 1.55555556;
  color: #000;
}
.editor > *:first-child {
  margin-top: 0;
}
.editor > *:last-child {
  margin-bottom: 0;
}
.text-mark {
  color: $color-black;
  background: $color-mint;
}
@media (max-width: 991px) {
  .editor .bt-link {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .editor-wrap {
    margin-top: 20px;
    margin-bottom: 25px;
  }
  .editor-wrap--large {
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .editor--medium--margins {
    margin-left: -15px;
    margin-right: -15px;
  }
  .editor h2,
  .editor h3 {
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .editor h2 {
    font-size: 24px;
    font-weight: $font-weight-semi-bold;
    line-height: 1.25;
  }
  .editor h3 {
    font-size: 22px;
    line-height: 1.33333333;
  }
  .editor p {
    margin-bottom: 30px;
    font-size: $font-small-s;
  }
  .editor p + p {
    margin-top: 25px;
  }
  .editor ul,
  .editor ol {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0 0 0 15px;
  }
  .editor ul,
  .editor ol,
  .editor ul p,
  .editor ol p {
    font-size: 16px;
  }
  .editor ul li,
  .editor ol li {
    margin-bottom: 15px;
    padding-left: 15px;
  }
  .editor--border-color {
    background-color: $color-mint;
    padding-left: 15px;
    padding-right: 15px;
  }
  .editor .bt-link {
    padding: 20px 20px;
    font-size: 20px;
  }
  .editor blockquote {
    margin: 45px 0;
    border-left: 10px solid $color-mint;
  }
  .editor blockquote p {
    margin-top: 0;
    margin-left: 35px;
    font-size: 16px;
  }
}
@media (max-width: $adaptive-mobile-small-xs) {
  .editor blockquote p {
    font-size: $font-small-sx;
  }
  .editor p {
    font-size: $font-small-sx;
  }
  .editor .bt-link {
    padding: 18px 18px;
    font-size: 16px;
  }
  .editor ul,
  .editor ol,
  .editor ul p,
  .editor ol p {
    font-size: 14px;
  }
}
/* #HEADERS
-------------------------------------------------- */
.header-h1,
.header-h2,
.header-h3 {
  font-weight: $font-weight-semi-bold;
  color: $color-black;
  word-wrap: break-word;
}
.header-h1 a,
.header-h2 a,
.header-h3 a {
  display: block;
  color: inherit;
  -webkit-transition: color .2s ease;
  transition: color .2s ease;
}
.header-h1 a:hover,
.header-h2 a:hover,
.header-h3 a:hover {
  color: $color-red;
  text-decoration: none;
}
.header-h1 {
  font-size: $font-large;
  color: $color-black;
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  line-height: 104%;
}
.header-h1--small {
  font-size: $font-special;
  line-height: 104%;
  font-weight: $font-weight-semi-bold;
}
.header-h2 {
  margin-bottom: 35px;
  font-size: $font-medium-sm;
  line-height: 1.3125;
}
.header-h3 {
  margin-bottom: 32px;
  font-size: $font-base-x;
  font-weight: $font-weight-semi-bold;
  line-height: 81%;
}
.header-h3--spacing {
  letter-spacing: 2.6px;
}
.header-h3--bold {
  margin-bottom: 35px;
  font-size: $font-base-x;
  font-weight: $font-weight-bold;
  line-height: 135%;
}
.header--mr-bottom {
  margin-bottom: 100px;
}
@media (max-width: $adaptive-second-device) {
  .header-h1 {
    font-size: $font-large-sm;
    line-height: 1.25;
  }
}
@media (max-width: $adaptive-mobile) {
  .header--mr-bottom {
    margin-bottom: 45px;
  }
  .header-center {
    font-size: 30px;
  }
  .header-h1 {
    font-size: 50px;
    line-height: 1.25;
  }
  .header-h3 {
    margin-bottom: 15px;
  }
}
@media (max-width: $adaptive-mobile-small) {
  .header-h1 {
    font-size: 40px;
    line-height: 1.23076923;
  }
}
@media (max-width: $adaptive-mobile-small-xs) {
  .header-h1 {
    font-size: 28px;
    line-height: 1.23076923;
  }
  .header-h1--small {
    font-size: 24px;
  }
  .header-h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: $font-weight-semi-bold;
    line-height: 1.23076923;
  }
  .header-h3 {
    font-size: 22px;
  }
  .header-h3--bold {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: $font-weight-semi-bold;
    line-height: 144%;
  }
  .header-h3--spacing {
    text-align: center;
    letter-spacing: 2px;
  }
}
@media (max-width: $adaptive-mobile-small-xs) {
  .header--mr-bottom {
    margin-bottom: 38px;
  }
}
