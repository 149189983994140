@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;500;600;700;800&display=swap");
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul,
ol,
li {
  list-style: none;
}

img {
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  margin: initial;
}

html,
body {
  height: 100%;
  line-height: 1;
  font-family: Montserrat, Arial;
  font-size: 18px;
  -webkit-tap-highlight-color: transparent;
  max-width: 2050px;
  margin: 0 auto !important;
}

._active--body {
  overflow: hidden;
}

._container {
  max-width: 2050px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
}

._ibg {
  position: relative;
}

._ibg img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

button {
  overflow: visible;
  font: inherit;
  text-transform: none;
  border: none;
  border: 0;
  background-color: transparent;
}
button:focus {
  outline: none;
}

@media (max-width: 991px) {
  ._container {
    padding: 0;
  }
}
/* здесь подключите шрифты*/
.wrapper {
  min-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}

.main-block {
  display: flex;
  flex-direction: column;
  position: relative;
}

@media (max-width: 320px) {
  .wrapper {
    overflow: auto;
  }
}
.divider-section {
  margin-top: 24px;
  margin-bottom: 45px;
  height: 2px;
  background-color: #F4F4F4;
}

.section-indent--large {
  margin-top: 100px;
  margin-bottom: 100px;
}

.section-indent--medium {
  margin-top: 75px;
  margin-bottom: 100px;
}

.section-indent--mr-bottom {
  margin-bottom: 100px;
}

.g-container {
  margin: 0 50px 0 50px;
  padding-right: 15px;
  padding-left: 65px;
}

.g-container-gap {
  display: flex;
  flex-direction: column;
  gap: 56px;
}

@media (max-width: 991px) {
  .section-indent--large {
    margin-top: 75px;
    margin-bottom: 75px;
  }
  .section-indent--medium {
    margin-top: 55px;
    margin-bottom: 75px;
  }
  .section-indent--mr-bottom {
    margin-bottom: 75px;
  }
}
@media (max-width: 767px) {
  .section-indent--large {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .section-indent--medium {
    margin-top: 40px;
    margin-bottom: 50px;
  }
}
@media (max-width: 991px) {
  .g-container {
    margin: 0 auto;
    padding-right: 20px;
    padding-left: 20px;
  }
}
.pure-g > div {
  box-sizing: border-box;
}

.pure-grid--margin-bottom-sm {
  margin-bottom: 40px;
}

.pure-grid--padding-right {
  padding-right: 48px;
}

.pure-grid--padding-right-sm {
  padding-right: 24px;
}

.pure-grid--padding-sm {
  padding: 30px 30px;
}

.pure-grid--space-around {
  justify-content: space-around;
}

.pure-grid--gap-medium {
  gap: 40px;
}

@media (max-width: 1441px) {
  .pure-grid--padding-right {
    padding-right: 28px;
  }
  .pure-grid--padding-right-sm {
    padding-right: 18px;
  }
}
@media (max-width: 991px) {
  .pure-grid--margin-bottom-sm {
    margin-bottom: 20px;
  }
}
.g-hide {
  display: none;
}

.g-show-sm {
  display: none;
}

.g-hide-sx {
  display: none;
}

.g-show-medium {
  display: none;
}

@media (max-width: 1025px) {
  .g-show-medium {
    display: initial;
  }
  .g-hide-medium {
    display: none;
  }
}
@media (max-width: 991px) {
  .g-hide-sx {
    display: initial;
  }
  .g-hide-sm {
    display: none;
  }
  .g-show-sm {
    display: initial;
  }
}
.error-section-wrap {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 470px;
  background-image: url("../../img/ErrorImage.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.error-section__title {
  margin: 0 auto;
  padding: 0 50px 50px 100px;
  box-sizing: border-box;
  letter-spacing: 0.35px;
  line-height: normal;
  font-size: 70px;
  font-weight: 800;
  color: #FFFFFF;
}

.error-section__ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.error-section__li {
  padding: 42px 58px;
}

.error-section_item {
  color: #1D1E25;
  font-weight: 600;
  font-size: 30px;
}

@media (max-width: 1441px) {
  .error-section__title {
    font-size: 47px;
  }
  .error-section__li {
    padding: 25px 25px;
  }
  .error-section_item {
    font-size: 26px;
  }
}
@media (max-width: 991px) {
  .error-section__ul {
    flex-direction: column;
  }
  .error-section-wrap {
    height: 461px;
  }
  .error-section_item {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .error-section__title {
    font-size: 35px;
    padding: 25px 25px;
  }
  .error-section_item {
    font-size: 22px;
  }
}
.search-wrap-modal {
  width: 100%;
  display: none;
  position: absolute;
  z-index: 33;
}

.search-container-modal {
  position: fixed;
  display: flex;
  height: 470px;
  background-image: url("../../img/SeacrhModalImage.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  max-width: 2050px;
}

.search-wrap {
  width: 100%;
  display: block;
}

.search-container {
  position: relative;
  display: flex;
  height: 358px;
  background-image: url("../../img/SeacrhModalImage.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}

.search-form-modal {
  display: flex;
  flex-direction: column;
  padding: 283px 50px 0 60px;
  position: relative;
  width: 1544px;
  margin: 0 auto;
}

.search-form {
  display: flex;
  flex-direction: column;
  padding: 154px 50px 0 60px;
  position: relative;
  width: 1544px;
  margin: 0 auto;
}

.search-form__input {
  display: block;
  width: 100%;
  padding: 0 160px 6px 0;
  font-size: 60px;
  font-weight: 800;
  line-height: normal;
  color: #FFFFFF;
  border: none;
  border-bottom: 8px solid #FFFFFF;
  background-color: transparent;
  outline: none;
  letter-spacing: 0.3px;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.search-form__button {
  cursor: pointer;
  position: absolute;
  right: 50px;
  margin-top: 25px;
  color: #FFFFFF;
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.2px;
}

.search-form__close-icon {
  position: absolute;
  cursor: pointer;
  width: 53px;
  height: auto;
  right: 50px;
  top: 150px;
}

.search-form__input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.search-content-wrap {
  display: flex;
  background-color: #FFFFFF;
  width: 100%;
}

.search-content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 1544px;
  margin: 0 auto;
  padding: 32px 60px 74px 60px;
}

.search-content__col-elem-block {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 51px;
}

.search-content__col-item {
  font-family: "Raleway";
  color: #76767C;
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: 103.023%;
}

.search-content-find-block {
  display: flex;
  flex-direction: column;
  padding: 0 50px 0 50px;
  gap: 34px;
}

.search-content-find__item {
  display: flex;
  flex-direction: column;
}

.search-content-find__item-title {
  color: #1D1E25;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 34px;
}

.search-content-find__item-title:hover {
  color: #93CFBD;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.search-content-find__item-text:hover {
  color: #93CFBD;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.search-content-find__item-text {
  color: #1D1E25;
  font-family: "Raleway";
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: 103.023%;
}

@media (max-width: 991px) {
  .search-container-modal {
    height: 337px;
  }
  .search-container {
    height: 337px;
  }
  .search-form__input {
    padding: 0 140px 6px 0;
    font-size: 47px;
  }
  .search-form-modal {
    padding: 160px 20px 0 20px;
  }
  .search-form {
    padding: 160px 20px 0 20px;
  }
  .search-form__button {
    right: 20px;
    margin-top: 15px;
    font-size: 35px;
  }
  .search-form__close-icon {
    right: 0;
    top: 80px;
  }
}
@media (max-width: 767px) {
  .search-form__input {
    font-size: 30px;
    padding: 0 0 6px 0;
  }
  .search-form__button {
    top: 236px;
    margin: 0;
    font-size: 26px;
  }
  .search-form__close-icon {
    width: 32px;
    height: 32px;
    right: 8px;
  }
  .search-content {
    padding: 20px 20px 50px 20px;
  }
  .search-content-find-block {
    padding: 0 20px 0 20px;
  }
}
@media (max-width: 450px) {
  .search-content-find__item-title {
    font-size: 28px;
  }
  .search-content-find__item-text {
    font-size: 18px;
  }
  .search-content__col-elem-block {
    margin-bottom: 30px;
  }
  .search-content__col-item {
    font-size: 16px;
  }
}
.about-gallery .owl-nav {
  display: none;
}

.about-gallery .owl-carousel .owl-dots {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 18px;
  margin-top: 37px;
}

.about-gallery .owl-dots .owl-dot {
  height: 17px;
  width: 17px;
  background-color: #BDBDBD;
}

.about-gallery .owl-dots .owl-dot.active {
  height: 17px;
  width: 17px;
  background-color: #93CFBD;
}

@media (max-width: 450px) {
  .about-gallery .owl-carousel .owl-dots {
    gap: 10px;
    margin-top: 25px;
  }
  .about-gallery .owl-dots .owl-dot {
    height: 10px;
    width: 10px;
  }
  .about-gallery .owl-dots .owl-dot.active {
    height: 10px;
    width: 10px;
  }
}
.editor-wrap {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  word-wrap: break-word;
}

.editor-wrap--large {
  position: relative;
  margin-top: 55px;
  margin-bottom: 55px;
  word-wrap: break-word;
}

.editor-wrap--small {
  margin-top: 20px;
  word-wrap: break-word;
}

.editor-wrap:first-child {
  margin-top: 0;
}

.editor-wrap:last-child {
  margin-bottom: 0;
}

.editor h2,
.editor h3 {
  margin-top: 45px;
  margin-bottom: 35px;
  font-family: "Montserrat";
  color: #1D1E25;
}

.editor h3 {
  font-weight: 600;
}

.editor h2 {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.3125;
}

.editor h3 {
  font-size: 26px;
  line-height: 1.3;
}

.editor p {
  font-family: "Raleway";
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 300;
  font-size: 26px;
  line-height: 123%;
  color: #1D1E25;
  letter-spacing: 0.3px;
}

.editor b,
.editor strong {
  font-weight: 600;
}

.editor small {
  font-size: 16px;
  line-height: 123%;
}

.editor i,
.editor em {
  font-style: italic;
}

.editor a {
  color: inherit;
}

.editor a:active,
.editor a:hover {
  text-decoration: none;
  outline: 0;
}

.editor a:not([class]) {
  position: relative;
  color: #93CFBD;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor: pointer;
  text-decoration: underline;
}

.editor a:not([class]):hover {
  opacity: 0.6;
  -webkit-transition: color 0.7s ease;
  transition: color 0.7s ease;
}

.editor .bt-link {
  display: flex;
  background-color: #F4F4F4;
  color: #76767C;
  line-height: 103%;
  font-weight: 600;
  font-size: 26px;
  padding: 18px 0 18px 32px;
  font-family: "Montserrat";
  width: 100%;
}

.editor .bt-link:hover {
  background-color: #CA0610;
  color: #FFFFFF;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.editor ul,
.editor ol {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0 0 0 50px;
  counter-reset: item;
}

.editor ul,
.editor ol,
.editor ul p,
.editor ol p {
  font-size: 26px;
  line-height: 123%;
  color: #1D1E25;
  letter-spacing: 0.3px;
}

.editor ul p,
.editor ol p {
  margin: 0;
}

.editor ul li {
  font-family: Raleway;
  position: relative;
  margin-bottom: 25px;
  padding-left: 20px;
  list-style-type: disc;
  font-weight: 300;
}

.editor ol > li {
  font-family: Raleway;
  position: relative;
  margin-bottom: 25px;
  padding-left: 20px;
  list-style: decimal;
  display: block;
  font-weight: 300;
}

.editor ol > li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}

.editor ul ul,
.editor ul ol {
  margin-top: 20px;
  margin-bottom: 20px;
}

.editor ol ul,
.editor ol ol {
  margin-top: 20px;
  margin-bottom: 20px;
}

.editor blockquote {
  border-left: 20px solid #93CFBD;
  margin-top: 65px;
  margin-bottom: 65px;
}

.editor blockquote p {
  margin: 0 0 0 55px;
  font-size: 26px;
  line-height: 123%;
  color: #1D1E25;
  letter-spacing: 0;
}

.editor .quote--gradient {
  margin-top: 55px;
  margin-bottom: 55px;
  font-weight: 600;
}

.editor .quote--mint {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 40px 30px;
  border-top: 3px solid #93CFBD;
  border-bottom: 3px solid #93CFBD;
}

.editor .quote--mint p {
  font-size: 26px;
  line-height: 1.55555556;
  color: #000;
}

.editor > *:first-child {
  margin-top: 0;
}

.editor > *:last-child {
  margin-bottom: 0;
}

.text-mark {
  color: #1D1E25;
  background: #93CFBD;
}

@media (max-width: 991px) {
  .editor .bt-link {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .editor-wrap {
    margin-top: 20px;
    margin-bottom: 25px;
  }
  .editor-wrap--large {
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .editor--medium--margins {
    margin-left: -15px;
    margin-right: -15px;
  }
  .editor h2,
  .editor h3 {
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .editor h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.25;
  }
  .editor h3 {
    font-size: 22px;
    line-height: 1.33333333;
  }
  .editor p {
    margin-bottom: 30px;
    font-size: 16px;
  }
  .editor p + p {
    margin-top: 25px;
  }
  .editor ul,
  .editor ol {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0 0 0 15px;
  }
  .editor ul,
  .editor ol,
  .editor ul p,
  .editor ol p {
    font-size: 16px;
  }
  .editor ul li,
  .editor ol li {
    margin-bottom: 15px;
    padding-left: 15px;
  }
  .editor--border-color {
    background-color: #93CFBD;
    padding-left: 15px;
    padding-right: 15px;
  }
  .editor .bt-link {
    padding: 20px 20px;
    font-size: 20px;
  }
  .editor blockquote {
    margin: 45px 0;
    border-left: 10px solid #93CFBD;
  }
  .editor blockquote p {
    margin-top: 0;
    margin-left: 35px;
    font-size: 16px;
  }
}
@media (max-width: 450px) {
  .editor blockquote p {
    font-size: 14px;
  }
  .editor p {
    font-size: 14px;
  }
  .editor .bt-link {
    padding: 18px 18px;
    font-size: 16px;
  }
  .editor ul,
  .editor ol,
  .editor ul p,
  .editor ol p {
    font-size: 14px;
  }
}
/* #HEADERS
-------------------------------------------------- */
.header-h1,
.header-h2,
.header-h3 {
  font-weight: 600;
  color: #1D1E25;
  word-wrap: break-word;
}

.header-h1 a,
.header-h2 a,
.header-h3 a {
  display: block;
  color: inherit;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

.header-h1 a:hover,
.header-h2 a:hover,
.header-h3 a:hover {
  color: #CA0610;
  text-decoration: none;
}

.header-h1 {
  font-size: 70px;
  color: #1D1E25;
  font-style: normal;
  font-weight: 600;
  line-height: 104%;
}

.header-h1--small {
  font-size: 40px;
  line-height: 104%;
  font-weight: 600;
}

.header-h2 {
  margin-bottom: 35px;
  font-size: 28px;
  line-height: 1.3125;
}

.header-h3 {
  margin-bottom: 32px;
  font-size: 26px;
  font-weight: 600;
  line-height: 81%;
}

.header-h3--spacing {
  letter-spacing: 2.6px;
}

.header-h3--bold {
  margin-bottom: 35px;
  font-size: 26px;
  font-weight: 700;
  line-height: 135%;
}

.header--mr-bottom {
  margin-bottom: 100px;
}

@media (max-width: 1441px) {
  .header-h1 {
    font-size: 60px;
    line-height: 1.25;
  }
}
@media (max-width: 991px) {
  .header--mr-bottom {
    margin-bottom: 45px;
  }
  .header-center {
    font-size: 30px;
  }
  .header-h1 {
    font-size: 50px;
    line-height: 1.25;
  }
  .header-h3 {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .header-h1 {
    font-size: 40px;
    line-height: 1.23076923;
  }
}
@media (max-width: 450px) {
  .header-h1 {
    font-size: 28px;
    line-height: 1.23076923;
  }
  .header-h1--small {
    font-size: 24px;
  }
  .header-h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.23076923;
  }
  .header-h3 {
    font-size: 22px;
  }
  .header-h3--bold {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 600;
    line-height: 144%;
  }
  .header-h3--spacing {
    text-align: center;
    letter-spacing: 2px;
  }
}
@media (max-width: 450px) {
  .header--mr-bottom {
    margin-bottom: 38px;
  }
}
.slider-container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.slider {
  display: flex;
  position: relative;
  height: 470px;
}

.slider-main-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  opacity: 0;
}

.slider-image--brightness {
  filter: brightness(50%);
}

.slider-main-image._active {
  display: block;
  opacity: 1;
  transition: all 0.6s ease;
}

.slider-controls {
  position: absolute;
  width: 100%;
  padding: 0 50px 0 20px;
}

.slider-controls-direction {
  position: relative;
  max-width: 1950px;
  margin: 0 auto;
  z-index: 10;
  top: 295px;
}

.prev-button {
  position: relative;
  float: left;
  width: 25px;
  height: 39px;
  outline: none;
  background-repeat: no-repeat;
  background-image: url(../../img/icons/SliderArrowLeft.svg);
  cursor: pointer;
  background-size: contain;
}

.next-button {
  position: absolute;
  right: 0;
  float: left;
  width: 25px;
  height: 39px;
  outline: none;
  background-repeat: no-repeat;
  background-image: url(../../img/icons/SliderArrowRight.svg);
  cursor: pointer;
  background-size: contain;
}

.prev-button::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 17px;
  height: 28px;
  margin-top: -13px;
  margin-left: -8px;
  background-repeat: no-repeat;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  content: "";
  cursor: pointer;
}

.next-button::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 17px;
  height: 28px;
  margin-top: -13px;
  margin-left: -8px;
  background-repeat: no-repeat;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  content: "";
}

.slider-content {
  position: absolute;
  width: 100%;
  padding: 0 170px 0 100px;
}

.slider-content-container {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1750px;
  margin: 0 auto;
  z-index: 8;
  top: 250px;
  gap: 4px;
}

.slider-content-container a:hover {
  color: #FFFFFF;
  opacity: 0.6;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.3s;
}

.slider-content-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.slider-content-menu span {
  color: #FFFFFF;
  height: 32px;
  font-weight: 300;
  font-size: 30px;
  border-left: 2px solid #FFFFFF;
  margin-left: 8px;
  padding-left: 8px;
}

.slider-content-menu span:first-child {
  border: none;
  margin-left: 0;
  padding-left: 0;
}

.slider-content-title {
  letter-spacing: 0.435px;
  font-size: 87px;
  color: #FFFFFF;
  font-weight: 800;
}

@media (max-width: 1441px) {
  .slider-content-menu span {
    color: #FFFFFF;
    height: 30px;
    font-size: 26px;
  }
  .slider-content-title {
    font-size: 70px;
  }
  .prev-button,
  .next-button {
    width: 17px;
    height: 25px;
  }
}
@media (max-width: 991px) {
  .slider {
    height: 461px;
  }
  .slider-controls-direction {
    top: 420px;
    margin: 0 auto;
  }
  .slider-controls {
    padding: 0 16px 0 16px;
  }
  .slider-controls-direction::after {
    position: absolute;
    top: 50%;
    right: 5px;
    height: 54px;
    margin-top: -13px;
    margin-right: -20px;
    content: "";
    z-index: -4;
    width: 50%;
  }
  .slider-content {
    padding: 0 16px 0 16px;
  }
  .slider-content-menu span {
    color: #FFFFFF;
    height: 23px;
    font-size: 20px;
  }
  .slider-content-title {
    font-size: 47px;
    line-height: 100%;
    letter-spacing: 0.235px;
  }
  .slider-content-container {
    gap: 8px;
    top: 280px;
  }
}
.slider-page-container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.slider-page {
  display: flex;
  position: relative;
  height: 470px;
}

.slider-page-content-wrap {
  display: flex;
  flex-direction: row;
  position: relative;
  max-width: 1950px;
  margin: 0 auto;
  align-items: center;
  top: 300px;
}

.slider-page-main-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  filter: brightness(40%);
}

.slider-page-controls {
  position: absolute;
  width: 100%;
}

.slider-page-controls-direction {
  position: relative;
  max-width: 1950px;
  margin: 0 auto;
  z-index: 10;
}

.prev-button {
  position: relative;
  float: left;
  width: 25px;
  height: 39px;
  outline: none;
  background-repeat: no-repeat;
  background-image: url(../../img/icons/SliderArrowLeft.svg);
  cursor: pointer;
  background-size: contain;
}

.next-button {
  position: absolute;
  right: 0;
  float: left;
  width: 25px;
  height: 39px;
  outline: none;
  background-repeat: no-repeat;
  background-image: url(../../img/icons/SliderArrowRight.svg);
  cursor: pointer;
  background-size: contain;
}

.prev-button::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 17px;
  height: 28px;
  margin-top: -13px;
  margin-left: -8px;
  background-repeat: no-repeat;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  content: "";
  cursor: pointer;
}

.next-button::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 17px;
  height: 28px;
  margin-top: -13px;
  margin-left: -8px;
  background-repeat: no-repeat;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  content: "";
}

.slider-page-content {
  position: absolute;
  width: 100%;
  padding: 0 50px 0 70px;
}

.slider-page-content-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  max-width: 1950px;
  z-index: 8;
  padding: 0 50px 0 80px;
}

.slider-page-content-title {
  line-height: normal;
  font-size: 30px;
  color: #FFFFFF;
  font-weight: 600;
  display: none;
}

.slider-page-content-title._active {
  display: block;
  opacity: 0;
  transition: 1s;
  animation-delay: 1s;
  animation: slidein 1s 1;
  animation-fill-mode: forwards;
}

@keyframes slidein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 1441px) {
  .prev-button,
  .next-button {
    width: 20px;
    height: 25px;
  }
}
@media (max-width: 991px) {
  .slider-page {
    height: 461px;
  }
  .slider-page-controls-direction {
    top: 420px;
    margin: 0 auto;
  }
  .slider-page-controls {
    padding: 0;
    bottom: 255px;
  }
  .slider-page-controls-direction::after {
    position: absolute;
    top: 50%;
    right: 5px;
    height: 54px;
    margin-top: -13px;
    margin-right: -20px;
    content: "";
    z-index: -4;
    width: 50%;
  }
  .slider-page-content {
    padding: 0 16px 0 16px;
  }
  .slider-page-content-title {
    font-size: 22px;
    letter-spacing: 0.235px;
  }
  .slider-page-content-wrap {
    top: 280px;
  }
  .slider-page-content-container {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .slider-content-title {
    font-size: 38px;
  }
  .slider-content-menu {
    white-space: nowrap;
  }
  .slider-content-menu span {
    color: #FFFFFF;
    height: 16px;
    font-size: 16px;
  }
}
@media (max-width: 450px) {
  .prev-button,
  .next-button {
    width: 17px;
    height: 25px;
  }
  .slider-page-content-title {
    font-size: 16px;
    word-wrap: normal;
  }
  .slider-content-title {
    font-size: 38px;
    word-break: break-word;
  }
}
@media (max-width: 325px) {
  .slider-content-title {
    font-size: 28px;
  }
  .slider-page-content-title {
    font-size: 12px;
  }
  .slider-content-menu span {
    height: 12px;
    font-size: 12px;
  }
}
@media (max-width: 2150px) {
  .slider--with-sidebar {
    margin: 0 auto 0 50px;
  }
}
@media (max-width: 991px) {
  .slider--with-sidebar {
    margin: 0 auto;
  }
}
.nav-menu-main-block {
  max-width: 2050px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
}

.nav-menu-main-ul {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.nav-menu-main-li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  min-height: 136px;
  cursor: pointer;
}

.nav-menu-main-li--item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  min-height: 136px;
  cursor: pointer;
}

.nav-menu-main-li--item > a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-decoration: none;
}

.nav-menu-main-li > a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-decoration: none;
  padding: 0 50px;
}

.nav-menu-main-li:hover {
  background-color: #CA0610;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.nav-menu-main-li:hover .nav-menu-main-text {
  display: initial;
}
.nav-menu-main-li:hover .nav-menu-main-link {
  display: none;
}

.nav-menu-main-li--item:hover {
  background-color: #CA0610;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.nav-menu-main-li--item:hover .nav-menu-main-link {
  color: #FFFFFF;
}

.nav-menu-main-link {
  color: #1D1E25;
  font-weight: 600;
  font-size: 38px;
  display: initial;
  cursor: pointer;
}

.nav-menu-main-text {
  display: none;
  line-height: 106%;
  font-size: 18px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal;
}

@media (max-width: 1025px) {
  .nav-menu-main-ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 18px;
    grid-row-gap: 40px;
    padding: 22px 18px 40px 18px;
  }
  .nav-menu-main-li {
    flex-direction: column;
    gap: 20px;
    width: 100%;
    min-height: 100%;
    align-items: center;
  }
  .nav-menu-main-li--item {
    flex-direction: column;
    width: 100%;
    min-height: 110px;
    align-items: center;
    justify-content: center;
  }
  .nav-menu-main-li > a {
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
  }
  .nav-menu-main-li:hover {
    background-color: #FFFFFF;
  }
  .nav-menu-main-li:hover .nav-menu-main-text {
    display: initial;
  }
  .nav-menu-main-li:hover .nav-menu-main-link {
    display: initial;
  }
  .nav-menu-main-li--item:hover {
    background-color: #FFFFFF;
  }
  .nav-menu-main-li--item:hover .nav-menu-main-link {
    color: #1D1E25;
  }
  .nav-menu-main-text {
    display: initial;
    color: #76767C;
    line-height: 106%;
  }
  .nav-menu-main-link {
    font-size: 30px;
  }
  .nav-menu-main-text {
    font-size: 14px;
  }
  .nav-menu-main-li > a {
    padding: 0;
  }
}
@media (max-width: 450px) {
  .nav-menu-main-li--item {
    min-height: 90px;
  }
  .nav-menu-main-text {
    display: initial;
    color: #76767C;
    line-height: 106%;
    font-size: 9px;
  }
  .nav-menu-main-link {
    font-size: 22px;
  }
}
@media (max-width: 325px) {
  .nav-menu-main-ul {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}
.link-news-events-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 46px 0 46px 0;
  background-color: #93CFBD;
}

.link-news-events-wrap:hover {
  background-color: #CA0610;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.link-news-events-block {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 6px solid #FFFFFF;
  margin-right: 24px;
  padding-right: 24px;
}

.link-news-events-item {
  line-height: 103%;
  font-weight: 800;
  color: #FFFFFF;
  font-size: 90px;
}

.link-news-events-wrap div:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
}

@media (max-width: 1441px) {
  .link-news-events-item {
    font-size: 70px;
  }
}
@media (max-width: 991px) {
  .link-news-events-item {
    font-size: 40px;
  }
  .link-news-events-block {
    border-right: 2px solid #FFFFFF;
    margin-right: 14px;
    padding-right: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .link-news-events-wrap {
    padding: 13px 0 13px 0;
  }
}
@media (max-width: 767px) {
  .link-news-events-item {
    font-size: 32px;
  }
}
@media (max-width: 450px) {
  .link-news-events-item {
    font-size: 24px;
  }
}
.menu-description-wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.menu-description-wrap > .menu-description-content-wrap:last-child > .menu-description-content {
  margin-bottom: 0;
}

.menu-description-wrap > .menu-description-content-wrap:last-child {
  border-bottom: none;
}

.menu-description-content-wrap {
  display: none;
}

.menu-description-content-wrap._active {
  display: block;
  border-bottom: 2px solid #F4F4F4;
}

.menu-description-tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 120px;
}

.tabs__shift-right {
  display: grid;
  grid-template-columns: 60% 40%;
}

.tabs__shift-left {
  display: grid;
  grid-template-columns: 40% 60%;
}

.menu-description-tabs > div:last-child {
  border-right: none;
}

.menu-description-content__close-bt {
  display: none;
}

.menu-description-tabs__block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 0 50px;
  border-bottom: 2px solid #F4F4F4;
  border-right: 2px solid #F4F4F4;
  cursor: pointer;
}

.menu-description-tabs__block._active {
  background-color: #CA0610;
  border-bottom: 2px solid #CA0610;
  border-right: 2px solid #CA0610;
}
.menu-description-tabs__block._active .menu-description-tabs__item {
  color: #FFFFFF;
}

.menu-description-tabs__block:hover {
  background-color: #CA0610;
  border-bottom: 2px solid #CA0610;
  border-right: 2px solid #CA0610;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.menu-description-tabs__block:hover .menu-description-tabs__item {
  color: #FFFFFF;
}

.menu-description-tabs__item {
  font-size: 30px;
  line-height: 103%;
  font-weight: 600;
  text-align: center;
  color: #1D1E25;
  word-break: normal;
}

.menu-description-content {
  display: none;
  flex-direction: column;
  max-width: 2050px;
  margin: 0 auto 85px auto;
  box-sizing: border-box;
  width: 100%;
}

.menu-description-content__block {
  display: flex;
  flex-direction: column;
  padding: 100px 15px 15px 65px;
  margin: 0 50px 0 50px;
}

.menu-description-content.active {
  display: flex;
}

.menu-description-content__title {
  line-height: 103%;
  font-weight: 600;
  color: #1D1E25;
  font-size: 70px;
  margin-bottom: 4.7%;
}

.menu-description-content__desc {
  color: #1D1E25;
  line-height: 113%;
  font-weight: 300;
  font-size: 26px;
  margin-bottom: 5%;
}

.menu-description-content__bt {
  background-color: #F4F4F4;
  color: #76767C;
  line-height: 103%;
  font-weight: 600;
  font-size: 26px;
  padding: 18px 0 18px 32px;
  width: 100vw;
}

.menu-description-content__bt:hover {
  background-color: #CA0610;
  color: #FFFFFF;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

@media (max-width: 1441px) {
  .menu-description-tabs__block {
    padding: 20px 50px;
  }
  .menu-description-tabs__item {
    font-size: 26px;
  }
}
@media (max-width: 991px) {
  .menu-description-wrap {
    display: initial;
  }
  .menu-description-tabs {
    flex-direction: column;
    margin: 0;
    border: none;
    height: auto;
  }
  .menu-description-tabs__block {
    padding: 32px 16px;
    border-right: none;
    border-bottom: 2px solid #F4F4F4;
    border-top: none;
  }
  .menu-description-tabs__block._active {
    border-right: 0;
    border-top: 2px solid #CA0610;
    border-bottom: 2px solid #CA0610;
  }
  .menu-description-tabs__item {
    line-height: 103%;
    font-size: 28px;
  }
  .menu-description-content__close-bt {
    display: initial;
    width: 35px;
    height: 35px;
    align-self: flex-end;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .menu-description-content__block {
    padding: 35px 35px;
    margin: 0;
  }
  .menu-description-content {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0 8px 22px rgba(188, 188, 188, 0.25);
    border-radius: 0.22em;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 56;
    margin: 0;
  }
  .menu-description-content__title {
    margin-bottom: 48px;
    line-height: 103%;
    font-size: 28px;
  }
  .menu-description-content__desc {
    line-height: 133%;
    font-size: 14px;
    margin-bottom: 0;
  }
  .menu-description-content__bt {
    display: none;
  }
  .tabs__shift-right {
    display: inherit;
  }
  .tabs__shift-left {
    display: inherit;
  }
}
@media (max-width: 767px) {
  .menu-description-tabs__item {
    font-size: 18px;
  }
}
.menu-link-blocks-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid: 420px 60px 420px/1fr 1fr 1fr;
  grid-template-areas: "item1 item2 item3" "item1 item5 item3" "item4 item5 item6";
  margin: 0 auto 0 50px;
}

.menu-link-blocks__block:nth-child(1) {
  grid-area: item1;
}

.menu-link-blocks__block:nth-child(2) {
  grid-area: item2;
}

.menu-link-blocks__block:nth-child(3) {
  grid-area: item3;
}

.menu-link-blocks__block:nth-child(4) {
  grid-area: item4;
}

.menu-link-blocks__block:nth-child(5) {
  grid-area: item5;
}

.menu-link-blocks__block:nth-child(6) {
  grid-area: item6;
}

.menu-link-blocks__block {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;
  padding: 40px 45px 40px 45px;
  word-break: normal;
  position: relative;
}

.menu-link-blocks-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: brightness(65%);
}

.menu-link-blocks__block:hover {
  background-color: #CA0610;
  transition: 0.6s;
}
.menu-link-blocks__block:hover .menu-link-blocks__name-block-default {
  display: none;
}
.menu-link-blocks__block:hover .menu-link-blocks__name-block-show {
  display: block;
}
.menu-link-blocks__block:hover .menu-link-blocks-image {
  visibility: hidden;
  opacity: 0;
  transition: visibility ease 0s, opacity ease 1s;
}

.menu-link-blocks__name-block-default {
  display: initial;
  color: #FFFFFF;
  font-size: 47px;
  font-weight: 700;
  letter-spacing: 0.235px;
  text-overflow: ellipsis;
  word-break: normal;
  z-index: 3;
}

.menu-link-blocks__name-block-show {
  color: #FFFFFF;
  font-size: 26px;
  font-weight: 600;
  line-height: 122%;
  text-overflow: ellipsis;
  word-break: normal;
  display: none;
}

@media (max-width: 1410px) {
  .menu-link-blocks__block {
    padding: 30px 35px;
  }
  .menu-link-blocks__name-block-default {
    font-size: 28px;
    word-break: break-word;
  }
  .menu-link-blocks__name-block-show {
    font-size: 20px;
  }
}
@media (max-width: 1441px) {
  .menu-link-blocks-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(320px, 380px);
  }
  .menu-link-blocks__block {
    padding: 20px 20px;
  }
  .menu-link-blocks__name-block-default {
    font-size: 24px;
    word-break: break-word;
  }
  .menu-link-blocks__name-block-show {
    font-size: 18px;
    word-break: break-word;
  }
}
@media (max-width: 991px) {
  .menu-link-blocks-wrap {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .menu-link-blocks-wrap .menu-link-blocks__block:first-child {
    padding: 71px 20px 20px 16px;
  }
  .menu-link-blocks__name-block-show {
    display: block;
    font-size: 20px;
  }
  .block-color--black {
    background-color: #1D1E25;
  }
  .block-color--mint {
    background-color: #93CFBD;
  }
  .block-color--gray {
    background-color: #76767C;
  }
  .menu-link-blocks__block {
    padding: 16px 20px 20px 16px;
    gap: 8px;
    align-items: flex-start;
  }
  .menu-link-blocks-image {
    display: none;
  }
  .menu-link-blocks__block:hover .menu-link-blocks__name-block-default {
    display: initial;
  }
  .menu-link-blocks__block:hover .menu-link-blocks__name-block-show {
    display: initial;
  }
}
@media (max-width: 767px) {
  .menu-link-blocks__name-block-default {
    font-size: 28px;
  }
  .menu-link-blocks__name-block-show {
    font-size: 20px;
  }
}
@media (max-width: 450px) {
  .menu-link-blocks__name-block-default {
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.07px;
  }
  .menu-link-blocks__name-block-show {
    font-size: 12px;
    line-height: 103%;
    font-weight: 500;
  }
}
.page-headline-video {
  display: flex;
  flex-direction: row;
  position: relative;
  pointer-events: none;
}

.page-headline-video:after {
  display: table;
  clear: both;
  content: " ";
}

.page-headline__media-video {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}

.page-headline__media-video:before {
  position: relative;
  display: block;
  height: 0;
  padding-top: 56.25%;
  content: "";
}

.page-headline__media-video .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.aside-block {
  position: fixed;
  width: 100vh;
  height: 50px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  bottom: -50px;
  transform: rotate(270deg);
  transform-origin: -2px 0;
  z-index: 45;
}

.sidebar-container-ul {
  list-style-type: none;
  padding: 0 155px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 27px;
}

.sidebar-container-li-item {
  color: #76767C;
  font-weight: 300;
  line-height: 103%;
  font-family: "Raleway";
  font-size: 20px;
}

.sidebar-item--active {
  color: #93CFBD;
}

.sidebar-container-li-item:hover {
  color: #93CFBD;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

@media (min-height: 550px) {
  .sidebar-container-ul {
    padding: 0 160px 0 0;
    gap: 47px;
  }
  .sidebar-container-li-item {
    font-size: 28px;
  }
}
@media (max-width: 1441px) {
  .sidebar-container-ul {
    padding: 0 120px 0 0;
  }
  .sidebar-container-li-item {
    font-size: 26px;
  }
}
@media (max-width: 991px) {
  .aside-block {
    display: none;
  }
}
.card-person-large {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Raleway";
  gap: 24px;
}

.card-person-large__name-block {
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 24px;
  cursor: default;
}

.card-person-large__name-person {
  color: rgba(29, 30, 37, 0.85);
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 103%;
  word-wrap: break-word;
}

.card-person-large__job-title {
  font-size: 22px;
  font-weight: 300;
  line-height: 103%;
  color: #76767C;
  word-wrap: break-word;
}

.card-person-large__achievements {
  display: initial;
  width: 60%;
  font-size: 26px;
  color: #76767C;
  line-height: 135%;
  font-weight: 300;
  word-wrap: break-word;
}

.card-person-large__media {
  display: none;
  width: 60%;
  position: relative;
  justify-content: center;
}

.card-person-large__image {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 1780px) {
  .card-person-large__name-block {
    width: 40%;
  }
}
@media (max-width: 1441px) {
  .card-person-large__name-block {
    gap: 20px;
  }
  .card-person-large__image {
    max-width: 533px;
  }
}
@media (max-width: 1025px) {
  .card-person-large {
    flex-direction: column;
    gap: 18px;
  }
  .card-person-large__media {
    display: flex;
    order: 1;
    width: 100%;
    justify-content: center;
  }
  .card-person-large__image {
    height: auto;
    position: initial;
    max-width: 100%;
  }
  .card-person-large__name-block {
    order: 2;
    width: 100%;
  }
  .card-person-large__achievements {
    order: 3;
    width: 100%;
  }
  .card-person-large__name-person {
    font-size: 35px;
  }
  .card-person-large__job-title {
    font-size: 20px;
  }
  .card-person-large__achievements {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .card-person-large__name-person {
    font-size: 35px;
  }
  .card-person-large__job-title {
    font-size: 20px;
  }
  .card-person-large__achievements {
    font-size: 24px;
  }
}
@media (max-width: 450px) {
  .card-person-large__name-person {
    font-size: 24px;
    line-height: 129%;
  }
  .card-person-large__job-title {
    font-size: 14px;
  }
  .card-person-large__achievements {
    font-size: 16px;
  }
}
.card-person-small {
  display: flex;
  flex-direction: column;
  font-family: "Raleway";
  gap: 34px;
}

.card-person-small__name-person {
  color: #76767C;
  line-height: 129%;
  font-weight: 300;
  font-size: 30px;
  word-wrap: break-word;
  cursor: default;
}

.card-person-small__job-title {
  line-height: 103%;
  font-weight: 300;
  color: #76767C;
  font-size: 16px;
  word-wrap: break-word;
}

.card-person-small__achievements {
  line-height: 103%;
  font-weight: 300;
  color: #76767C;
  font-size: 20px;
  word-wrap: break-word;
}

.card-person-small__media {
  display: none;
  width: 100%;
  position: relative;
}

.card-person-small__image {
  width: 100%;
  max-width: 477px;
  height: auto;
  object-fit: contain;
  object-position: center;
  background-repeat: no-repeat;
  bottom: 0;
}

@media (max-width: 1441px) {
  .card-person-small {
    gap: 30px;
  }
}
@media (max-width: 1025px) {
  .card-person-small {
    gap: 18px;
  }
  .card-person-small__media {
    display: flex;
    order: 1;
  }
  .card-person-small__image {
    height: auto;
    position: initial;
  }
  .card-person-small__name-person {
    order: 2;
    font-size: 26px;
  }
  .card-person-small__job-title {
    order: 3;
    font-size: 14px;
  }
  .card-person-small__achievements {
    order: 4;
    font-size: 18px;
  }
}
@media (max-width: 450px) {
  .card-person-small__image {
    max-width: 200px;
  }
  .card-person-small__name-person {
    font-size: 18px;
  }
  .card-person-small__job-title {
    font-size: 9px;
  }
  .card-person-small__achievements {
    font-size: 12px;
  }
}
.design-template-wrap {
  display: flex;
  flex-direction: column;
}

.design-template-wrap > div:nth-child(2) {
  display: block;
}

.design-template-menu-title {
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-bottom: 70px;
}

.design-template__title {
  line-height: 103%;
  font-weight: 600;
  font-size: 70px;
  color: #1D1E25;
}

.design-template__menu {
  display: flex;
  flex-wrap: wrap;
  gap: 37px;
}

.design-template__menu-item {
  line-height: 103%;
  font-weight: 600;
  font-size: 20px;
  color: #BDBDBD;
  cursor: pointer;
}

.design-template-card--fonts {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.design-template__menu-item._active {
  color: #93CFBD;
}

.design-template__menu-item:hover {
  color: #93CFBD;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.design-template-download__bt {
  margin-top: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  max-width: 160px;
}

.design-template-download--icon {
  width: 30px;
  height: 30px;
  background-image: url("../../img/icons/DownLoadIcon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.design-template-download--link {
  font-weight: 300;
  font-size: 30px;
  font-family: "Raleway";
  color: #1D1E25;
  text-decoration: underline #1D1E25;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
}

.design-template-download__bt:hover {
  color: #93CFBD;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}
.design-template-download__bt:hover .design-template-download--icon {
  background-image: url("../../img/icons/DownLoadIconActive.svg");
}
.design-template-download__bt:hover .design-template-download--link {
  color: #93CFBD;
  text-decoration: underline #93CFBD;
}

.design-template-card-wrap .owl-nav {
  display: none;
}

.design-template-card-wrap .owl-carousel .owl-dots {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 18px;
  margin-top: 37px;
}

.design-template-card-wrap .owl-dots .owl-dot {
  height: 17px;
  width: 17px;
  background-color: #BDBDBD;
}

.design-template-card-wrap .owl-dots .owl-dot.active {
  height: 17px;
  width: 17px;
  background-color: #93CFBD;
}

.design-template-card__text {
  line-height: 133%;
  font-size: 26px;
  color: #1D1E25;
}

@media (max-width: 1410px) {
  .design-template__menu {
    gap: 24px;
  }
}
@media (max-width: 1441px) {
  .design-template__title {
    font-size: 47px;
  }
  .design-template__menu-item {
    font-size: 18px;
  }
}
@media (max-width: 991px) {
  .design-template__menu {
    gap: 20px;
  }
  .design-template-menu-title {
    margin-bottom: 30px;
  }
  .design-template-download__bt {
    margin-top: 30px;
  }
  .design-template-download--icon {
    width: 24px;
    height: 24px;
  }
  .design-template-download--link {
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  .design-template__menu {
    gap: 16px;
  }
  .design-template-download__bt {
    gap: 6px;
  }
  .design-template-download--icon {
    width: 14px;
    height: 14px;
  }
  .design-template-download--link {
    font-size: 16px;
  }
  .design-template-card__text {
    font-size: 16px;
  }
}
@media (max-width: 450px) {
  .design-template__title {
    font-size: 28px;
  }
  .design-template__menu-item {
    font-size: 12px;
  }
  .design-template-card-wrap .owl-carousel .owl-dots {
    margin-top: 25px;
    gap: 10px;
  }
  .design-template-card-wrap .owl-dots .owl-dot {
    height: 10px;
    width: 10px;
  }
  .design-template-card-wrap .owl-dots .owl-dot.active {
    height: 10px;
    width: 10px;
  }
}
.design-template-card-wrap {
  display: none;
}

.design-template-card-wrap > div:last-child {
  margin-bottom: 0;
}

.design-template-card--color-gray {
  background-color: #76767C;
}

.design-template-card--color-light-gray {
  background-color: #BDBDBD;
}

.design-template-card--color-red {
  background-color: #CA0610;
}

.design-template-card--color-light-black {
  background-color: #4a4a4a;
}

.design-template-card--color-mint {
  background-color: #93CFBD;
}

.design-template-card__color-block {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 36px;
  padding: 50px 50px;
}

.design-template-card__font {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.design-template-card__block-text {
  font-size: 30px;
  color: #FFFFFF;
  font-weight: 300;
  font-family: "Raleway";
}

@media (max-width: 1441px) {
  .design-template-card__font {
    gap: 6px;
  }
}
@media (max-width: 1025px) {
  .design-template-card__font {
    gap: 18px;
    flex-direction: column;
  }
  .design-template-card__color-block {
    padding: 20px 20px;
    gap: 18px;
  }
}
@media (max-width: 767px) {
  .design-template-card__block-text {
    font-size: 24px;
  }
  .design-template-card-wrap .owl-dots .owl-dot {
    width: 14px;
    height: 14px;
  }
  .design-template-card-wrap .owl-dots .owl-dot.active {
    width: 14px;
    height: 14px;
  }
}
@media (max-width: 450px) {
  .design-template-card__block-text {
    font-size: 9px;
  }
}
.photo-repository-wrap {
  display: flex;
  flex-direction: column;
}

.photo-repository-wrap > div:nth-child(2) {
  display: block;
}

.photo-repository-menu-title {
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-bottom: 70px;
}

.photo-repository__title {
  line-height: 103%;
  font-weight: 600;
  font-size: 70px;
  color: #1D1E25;
}

.photo-repository__menu {
  display: flex;
  flex-wrap: wrap;
  gap: 37px;
}

.photo-repository__menu-item {
  line-height: 103%;
  font-weight: 600;
  font-size: 20px;
  color: #BDBDBD;
  cursor: pointer;
}

.photo-repository__menu-item._active {
  color: #93CFBD;
}

.photo-repository__menu-item:hover {
  color: #93CFBD;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.photo-repository-download__bt {
  font-weight: 300;
  font-size: 30px;
  margin-top: 20px;
  font-family: "Raleway";
  color: #1D1E25;
  cursor: pointer;
}

.photo-repository-download__bt:hover {
  color: #93CFBD;
}

.photo-repository-card-wrap .owl-nav {
  display: none;
}

.photo-repository-card-wrap .owl-carousel .owl-dots {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 18px;
  margin-top: 37px;
}

.photo-repository-card-wrap .owl-dots .owl-dot {
  height: 17px;
  width: 17px;
  background-color: #BDBDBD;
}

.photo-repository-card-wrap .owl-dots .owl-dot.active {
  height: 17px;
  width: 17px;
  background-color: #93CFBD;
}

.photo-repository-modal-wrap._active {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 30, 37, 0.9);
  z-index: 56;
}

.photo-repository-modal-wrap {
  display: none;
}

.photo-repository-modal {
  padding: 50px 20px 50px 20px;
  max-height: 100%;
}

.photo-repository-modal__close-btn:after {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  top: 20px;
  right: 50px;
  background-image: url("../../img/icons/CloseIconPhotoModal.svg");
  background-size: cover;
  cursor: pointer;
}

.photo-repository-modal .photo-repository-card__media {
  cursor: default;
}

.photo-repository-download__bt {
  display: none;
}

.fancybox__caption .photo-repository-download__bt {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  max-width: 160px;
}

.photo-repository-modal-wrap._active .photo-repository-modal .pure-img {
  max-width: 100%;
  max-height: 80vh;
  display: block;
}

.photo-repository-download--icon {
  width: 30px;
  height: 30px;
  background-image: url("../../img/icons/DownLoadIconWhite.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.photo-repository-download--link {
  font-weight: 300;
  font-size: 30px;
  font-family: "Raleway";
  color: #FFFFFF;
  text-decoration: underline #FFFFFF;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
}

.photo-repository-download__bt:hover {
  color: #93CFBD;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}
.photo-repository-download__bt:hover .photo-repository-download--icon {
  background-image: url("../../img/icons/DownLoadIconActive.svg");
}
.photo-repository-download__bt:hover .photo-repository-download--link {
  color: #93CFBD;
  text-decoration: underline #93CFBD;
}

.fancybox__container .is-compact .fancybox__footer {
  position: relative;
}

.figcaption__block {
  display: flex;
  flex-direction: column;
}

.fancybox__container .fancybox-image {
  margin: auto;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 0;
  object-fit: cover;
  user-select: none;
}

figcaption {
  display: none;
}

.figcaption__block p {
  font-size: 16px;
}

@media (max-width: 1410px) {
  .photo-repository__menu {
    gap: 24px;
  }
}
@media (max-width: 1441px) {
  .photo-repository__title {
    font-size: 47px;
  }
  .photo-repository__menu-item {
    font-size: 18px;
  }
}
@media (max-width: 991px) {
  .photo-repository-menu-title {
    margin-bottom: 30px;
  }
  .photo-repository__menu {
    gap: 20px;
  }
  .photo-repository-modal__close-btn:after {
    right: 20px;
  }
  .photo-repository-download--icon {
    width: 24px;
    height: 24px;
  }
  .photo-repository-download--link {
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  .photo-repository__title {
    font-size: 28px;
  }
  .photo-repository__menu {
    gap: 16px;
  }
  .photo-repository__menu-item {
    font-size: 12px;
  }
  .photo-repository-download__bt {
    gap: 6px;
  }
  .photo-repository-download--icon {
    width: 14px;
    height: 14px;
  }
  .photo-repository-download--link {
    font-size: 16px;
  }
  .figcaption__block p {
    font-size: 14px;
  }
}
@media (max-width: 450px) {
  .photo-repository-modal__close-btn:after {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
  .photo-repository-card-wrap .owl-carousel .owl-dots {
    margin-top: 25px;
    gap: 10px;
  }
  .photo-repository-card-wrap .owl-dots .owl-dot {
    height: 10px;
    width: 10px;
  }
  .photo-repository-card-wrap .owl-dots .owl-dot.active {
    height: 10px;
    width: 10px;
  }
  .photo-repository-download__bt {
    margin-top: 15px;
  }
}
.photo-repository-card-wrap {
  display: none;
}

.photo-repository-card-wrap > div:last-child {
  margin-bottom: 0;
}

.photo-repository-card--color-gray {
  background-color: #76767C;
}

.photo-repository-card--color-light-gray {
  background-color: #BDBDBD;
}

.photo-repository-card--color-red {
  background-color: #CA0610;
}

.photo-repository-card--color-light-black {
  background-color: #4a4a4a;
}

.photo-repository-card--color-mint {
  background-color: #93CFBD;
}

.photo-repository-card__text {
  line-height: 133%;
  font-size: 20px;
  color: #1D1E25;
}

.photo-repository-card__color-block {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 36px;
  padding: 50px 50px;
}

.photo-repository-card__block-text {
  font-size: 30px;
  color: #FFFFFF;
  font-weight: 300;
  font-family: "Raleway";
}

.photo-repository-card__media {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  width: 100%;
  cursor: pointer;
}

.photo-repository-card-wrap .owl-carousel .owl-stage-outer {
  display: flex;
}

.photo-repository-card-wrap .owl-carousel .owl-stage {
  display: -webkit-box;
  display: -moz-box;
}

.photo-repository-card-wrap .owl-carousel .owl-item {
  width: max-content !important;
  cursor: pointer;
}

.photo-repository-card-wrap .owl-carousel .owl-item img {
  width: auto !important;
}

.photo-repository-card__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 767px) {
  .photo-repository-card__image {
    background-size: cover;
  }
}
.forms-tab-wrap {
  display: flex;
  flex-direction: column;
}

.forms-tab__pos-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 57px;
}

.forms-tab__pos-row .forms-tab__item {
  width: 100%;
  margin-bottom: 0;
}

.forms-tab__menu-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 57px;
}

.forms-tab__menu-tabs-item {
  padding: 13px 37px 13px 37px;
  background-color: #F4F4F4;
  font-size: 32px;
  line-height: 103%;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  color: #1D1E25;
}

.forms-tab__menu-tabs-item._active {
  background-color: #93CFBD;
  color: #FFFFFF;
}

.forms-tab__level-tabs:not(._active) {
  display: none;
}

.forms-tab__level-tabs._active {
  margin-top: 80px;
}

.forms-tab__menu-tabs-item:hover {
  background-color: #93CFBD;
  color: #FFFFFF;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.forms-tab-wrap div:last-child {
  margin-bottom: 0;
}

.forms-tab__content {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forms-tab__content:not(._active) {
  display: none;
}

.forms-tab__form {
  display: none;
}

.forms-tab__block-level-2 {
  display: none;
}

.forms-tab__block {
  margin-bottom: 30px;
}

.forms-tab__item {
  padding: 13px 37px 13px 37px;
  background-color: #F4F4F4;
  font-size: 32px;
  line-height: 103%;
  font-weight: 600;
  cursor: pointer;
  color: #1D1E25;
  margin-bottom: 30px;
}

.forms-tab__item._active {
  background-color: #93CFBD;
  color: #FFFFFF;
}

.forms-tab__item:hover {
  background-color: #93CFBD;
  color: #FFFFFF;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

@media (max-width: 1441px) {
  .forms-tab__pos-row {
    flex-direction: column;
    gap: 30px;
  }
  .forms-tab__menu-tabs {
    flex-direction: column;
    gap: 30px;
  }
}
@media (max-width: 991px) {
  .forms-tab__item {
    padding: 20px 20px;
    font-size: 24px;
    margin-bottom: 24px;
  }
  .forms-tab__menu-tabs-item {
    padding: 20px 20px;
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .forms-tab__pos-row .forms-tab__item {
    padding: 20px 20px;
  }
  .forms-tab__menu-tabs {
    flex-direction: column;
    gap: 18px;
  }
  .forms-tab__level-tabs._active {
    margin-top: 50px;
  }
  .forms-tab__content {
    margin-top: 45px;
  }
}
@media (max-width: 450px) {
  .forms-tab__item {
    padding: 20px 20px;
    font-size: 14px;
  }
  .forms-tab__menu-tabs-item {
    padding: 20px 20px;
    font-size: 14px;
  }
  .forms-tab__block {
    margin-bottom: 18px;
  }
  .forms-tab__pos-row {
    gap: 18px;
  }
}
.forms-tab__form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.forms-tab__form .form-type-textfield {
  margin-bottom: 45px;
}

.forms-tab__form .form-type-email {
  margin-bottom: 45px;
}

.forms-tab__form .form-type-url {
  margin-bottom: 45px;
}

.forms-tab__form .form-type-url .form-url {
  outline: none;
  background-color: #F4F4F4;
  color: #76767C;
  font-family: Raleway;
  font-size: 30px;
  font-weight: 300;
  line-height: 103.023%;
  padding: 15px 40px;
  width: 100%;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.forms-tab__form .form-type-email .form-email {
  outline: none;
  background-color: #F4F4F4;
  color: #76767C;
  font-family: Raleway;
  font-size: 30px;
  font-weight: 300;
  line-height: 103.023%;
  padding: 15px 40px;
  width: 100%;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.webform-submission-form .form-type-email .form-email {
  background-color: #F4F4F4;
  color: #76767C;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.forms-tab__form .form-type-textfield .form-text {
  outline: none;
  background-color: #F4F4F4;
  color: #76767C;
  font-family: Raleway;
  font-size: 30px;
  font-weight: 300;
  line-height: 103.023%;
  padding: 15px 40px;
  width: 100%;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.forms-tab__form .form-type-textarea {
  margin-bottom: 45px;
}

.forms-tab__form .form-type-textarea .form-textarea {
  width: 100%;
  line-height: 103%;
  font-family: Raleway;
  font-weight: 300;
  font-size: 30px;
  color: #76767C;
  height: 229px;
  resize: none;
  outline: none;
  background-color: #F4F4F4;
  padding: 15px 40px;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.forms-tab__form .form-actions {
  display: flex;
  justify-content: flex-end;
}

.forms-tab__form .form-actions .webform-button--submit {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  line-height: 103.023%;
  color: #FFFFFF;
  background: #76767C;
  padding: 12px 87px 12px 87px;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.forms-tab__form .form-actions .webform-button--submit:hover {
  background: #CA0610;
}

.webform-submission-podpiska-na-rassylku-form .form-type-email {
  float: left;
  width: 80%;
  max-width: 100%;
}

.webform-submission-podpiska-na-rassylku-form .webform-actions {
  display: flex;
}

.webform-submission-podpiska-na-rassylku-form .webform-actions .webform-button--submit {
  width: 100%;
}

.webform-submission-form .js-webform-states-hidden {
  padding: 0 0 45px 0;
}

/*------Чекбокс-------------------------------------------------------------------------------------------------------*/
.forms-tab__form .form-type-checkbox {
  margin-bottom: 25px;
  position: relative;
}

.forms-tab__form .form-type-checkbox .form-checkbox {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
}

.forms-tab__form .form-type-checkbox .option {
  color: #76767C;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 103.023%;
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 0.4% 16px 0.4% 48px;
  z-index: 3;
  overflow: hidden;
}

input:checked ~ .checkmark::after {
  display: block;
  background-color: #CA0610;
}

input:checked ~ .checkmark {
  background-color: #D9D9D9;
}

.forms-tab__form .form-type-checkbox .checkmark:after {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #CA0610;
  cursor: pointer;
  border: none;
  transform: initial;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.forms-tab__form .form-type-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #D9D9D9;
  cursor: pointer;
  transform: initial;
  border: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

/*------Группа чекбоксов-------------------------------------------------------------------------------------------------*/
.forms-tab__form .checkboxes--wrapper {
  margin-bottom: 45px;
}

.forms-tab__form .checkboxes--wrapper .fieldset-legend {
  color: #76767C;
  line-height: 155%;
  font-size: 20px;
}

.forms-tab__form .fieldset-wrapper .webform-options-display-two-columns {
  display: block;
  max-width: 100%;
  margin-top: 0.4em;
  margin-bottom: 0.5em;
  column-count: 4;
}

/*------Сообщение об ошибке-------------------------------------------------------------------------------------------*/
.forms-tab__form .form-type-email .form-item--error-message {
  color: #CA0610;
  margin-top: 8px;
  font-size: 14px;
}

.forms-tab__form .form-item--error-message {
  color: #CA0610;
  margin-top: 8px;
  font-size: 14px;
}

/*------Форма телефон-------------------------------------------------------------------------------------------------*/
.forms-tab__form .webform-telephone--wrapper {
  margin-bottom: 45px;
}

.forms-tab__form .webform-telephone--wrapper .fieldset-legend {
  color: #76767C;
  line-height: 155%;
  font-size: 20px;
}

.forms-tab__form .webform-telephone--wrapper .form-type-tel .form-tel {
  outline: none;
  background-color: #F4F4F4;
  color: #76767C;
  font-family: Raleway;
  font-size: 30px;
  font-weight: 300;
  line-height: 103.023%;
  padding: 15px 40px 15px 50px;
  width: 100%;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

/*------Форма дата----------------------------------------------------------------------------------------------------*/
.forms-tab__form .form-type-datetime {
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
}

.forms-tab__form .form-type-datetime .form-required {
  color: #76767C;
  line-height: 155%;
  font-size: 20px;
  margin-right: 12px;
}

.forms-tab__form .form-type-datetime .form-type-date .form-date {
  outline: none;
  background-color: #F4F4F4;
  color: #76767C;
  font-family: Raleway;
  font-size: 30px;
  font-weight: 300;
  line-height: 103.023%;
  padding: 15px 15px;
  width: 100%;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.forms-tab__form .form-type-webform-time .form-time {
  outline: none;
  background-color: #F4F4F4;
  color: #76767C;
  font-family: Raleway;
  font-size: 30px;
  font-weight: 300;
  line-height: 103.023%;
  padding: 15px 15px;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

/*------Форма файл---------------------------------------------------------------------------------------------------*/
.forms-tab__form .form-type-managed-file {
  margin-bottom: 45px;
}

.forms-tab__form .form-type-managed-file label {
  background-color: #76767C;
  color: #FFFFFF;
  font-size: 32px;
  font-weight: 600;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 56px;
  max-width: 435px;
  margin-bottom: 15px;
}

.forms-tab__form .form-type-managed-file label:hover {
  background-color: #CA0610;
}

.forms-tab__form .form-type-managed-file .form-managed-file {
  margin-bottom: 15px;
}

.forms-tab__form .form-type-managed-file .description {
  color: #76767C;
  line-height: 135%;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 25px;
}

/*------Форма радиочекбокс---------------------------------------------------------------------------------------------*/
.forms-tab__form .fieldset-wrapper .webform-options-display-buttons {
  display: flex;
  gap: 10px;
}

.forms-tab__form .fieldset-wrapper .webform-options-display-buttons .webform-options-display-buttons-wrapper {
  cursor: pointer;
  color: #76767C;
  font-size: 24px;
  border-color: #76767C;
  margin: 0;
}

.forms-tab__form .fieldset-wrapper .webform-options-display-buttons .webform-options-display-buttons-label {
  display: table-cell;
  height: 100%;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  color: #76767C;
  border: 1px solid #76767C;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  background-color: #fff;
}

.forms-tab__form .fieldset-wrapper .webform-options-display-buttons input:checked + label.webform-options-display-buttons-label {
  color: #93CFBD;
  border-color: #93CFBD;
}

.forms-tab__form .checkboxes--wrapper .fieldset-legend {
  color: #76767C;
  line-height: 155%;
  font-size: 20px;
}

.forms-tab__form .fieldset-wrapper .form-type-checkbox {
  margin-bottom: 25px;
}

.forms-tab__form .fieldset-wrapper {
  margin-bottom: 25px;
}

.webform-options-display-buttons input:checked:focus + label.webform-options-display-buttons-label {
  box-shadow: none;
}

.webform-options-display-buttons input:checked + label.webform-options-display-buttons-label {
  color: #93CFBD;
  border-color: #93CFBD;
}

.webform-options-display-buttons input:hover + label.webform-options-display-buttons-label {
  color: #93CFBD;
  border-color: #93CFBD;
}

@media (max-width: 991px) {
  .webform-submission-podpiska-na-rassylku-form .webform-actions .webform-button--submit {
    width: 100%;
  }
  .forms-tab__form .fieldset-wrapper .webform-options-display-two-columns {
    column-count: 2;
  }
}
@media (max-width: 767px) {
  .forms-tab__form .form-type-textfield .form-text {
    padding: 10px 16px;
    font-size: 15px;
  }
  .forms-tab__form .form-type-email .form-email {
    padding: 10px 16px;
    font-size: 15px;
  }
  .webform-submission-form .form-type-email .form-email {
    padding: 10px 16px;
    font-size: 15px;
  }
  .forms-tab__form .form-type-url .form-url {
    padding: 10px 16px;
    font-size: 15px;
  }
  .forms-tab__form .webform-telephone--wrapper .form-type-tel .form-tel {
    padding: 10px 16px 10px 45px;
    font-size: 15px;
  }
  .forms-tab__form .form-type-datetime .form-type-date .form-date {
    font-size: 15px;
    padding: 10px 10px;
  }
  .forms-tab__form .form-type-webform-time .form-time {
    font-size: 15px;
    padding: 10px 10px;
  }
  .forms-tab__form .form-type-textarea .form-textarea {
    padding: 10px 16px;
    font-size: 15px;
    height: 147px;
  }
  .forms-tab__form .form-actions .webform-button--submit {
    width: 100%;
    padding: 10px 10px;
    font-size: 18px;
  }
  .forms-tab__form .form-type-managed-file label {
    max-width: 100%;
    padding: 10px 10px;
    font-size: 18px;
    height: auto;
    margin-bottom: 15px;
  }
  .forms-tab__form .webform-telephone--wrapper .fieldset-legend {
    font-size: 12px;
  }
  .forms-tab__form .checkboxes--wrapper .fieldset-legend {
    font-size: 12px;
  }
  .forms-tab__form .form-type-datetime .form-required {
    font-size: 12px;
  }
  .forms-tab__form .form-type-textfield {
    margin-bottom: 15px;
  }
  .forms-tab__form .form-type-email {
    margin-bottom: 15px;
  }
  .forms-tab__form .form-type-url {
    margin-bottom: 15px;
  }
  .forms-tab__form .webform-telephone--wrapper {
    margin-bottom: 15px;
  }
  .forms-tab__form .form-type-datetime {
    margin-bottom: 15px;
  }
  .forms-tab__form .checkboxes--wrapper {
    margin-bottom: 15px;
  }
  .forms-tab__form .fieldset-wrapper .form-type-checkbox {
    margin-bottom: 15px;
  }
  .forms-tab__form .fieldset-wrapper {
    margin-bottom: 15px;
  }
  .forms-tab__form .form-type-textarea {
    margin-bottom: 15px;
  }
  .forms-tab__form .form-type-managed-file .description {
    margin-bottom: 15px;
  }
  .forms-tab__form .form-type-datetime .form-type-date {
    margin-bottom: 15px;
  }
  .forms-tab__form .form-type-checkbox .option {
    padding: 0.5% 0 0.4% 37px;
  }
  .forms-tab__form .form-type-radio .option {
    padding: 0.5% 0 0.4% 37px;
  }
  .forms-tab__form .form-type-managed-file .description {
    font-size: 11px;
    margin-top: 4px;
  }
  .forms-tab__form .radios--wrapper .fieldset-wrapper .webform-options-display-buttons {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
  .forms-tab__form .fieldset-wrapper .webform-options-display-buttons .webform-options-display-buttons-wrapper {
    font-size: 15px;
  }
  .forms-tab__form .form-item--error-message {
    font-size: 10px;
  }
  .forms-tab__form .form-type-email .form-item--error-message {
    font-size: 10px;
  }
  .forms-tab__form .form-type-radio .option {
    padding: 20px 20px;
    font-size: 15px;
  }
  .webform-submission-form .form-type-email {
    float: left;
    max-width: 100%;
    width: 100%;
    margin-bottom: 15px;
  }
  .forms-tab__form .webform-submission-form .form-actions .webform-button--submit {
    width: 100%;
  }
  .webform-submission-form .checkboxes--wrapper .webform-options-display-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
  }
  .webform-submission-form .checkboxes--wrapper .webform-options-display-buttons .webform-options-display-buttons-wrapper {
    display: block;
    margin: 5px 5px;
  }
  .webform-submission-form .js-webform-states-hidden {
    padding: 0 0 15px 0;
  }
}
@media (max-width: 450px) {
  .forms-tab__form .form-actions .webform-button--submit {
    font-size: 13px;
  }
  .forms-tab__form .form-type-managed-file label {
    font-size: 13px;
  }
  .forms-tab__form .form-type-checkbox .option {
    font-size: 10px;
    padding: 0.5% 0 0.4% 37px;
  }
  .forms-tab__form .form-type-checkbox .checkmark:after {
    height: 18px;
    width: 18px;
  }
  .forms-tab__form .form-type-checkbox .checkmark {
    height: 18px;
    width: 18px;
    top: 3px;
  }
  .webform-submission-form .checkboxes--wrapper .webform-options-display-buttons .webform-options-display-buttons-wrapper .form-type-checkbox .webform-options-display-buttons-label option {
    display: table-cell;
    height: 100%;
    padding: 10px 5px 10px 5px;
    text-align: center;
    vertical-align: middle;
    color: #666;
    border: 3px solid #ccc;
    border-top-color: rgb(204, 204, 204);
    border-right-color: rgb(204, 204, 204);
    border-bottom-color: rgb(204, 204, 204);
    border-left-color: rgb(204, 204, 204);
    border-radius: 3px;
    background-color: #fff;
    font-size: 12px;
  }
}
.spbgu-cookie-form .cookie-block {
  background-color: #F4F4F4;
  width: 100%;
  min-width: 20rem;
  position: fixed;
  left: auto;
  bottom: 0;
  z-index: 100;
  display: flex;
  padding: 28px 119px 28px 119px;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  justify-content: center;
}

.spbgu-cookie-form .cookie-block span {
  color: #1D1E25;
  display: inline-block;
  font-weight: 300;
  line-height: normal;
  font-size: 16px;
  font-family: "Raleway";
}

.spbgu-cookie-form .cookie-block span .form__submit-bt {
  color: #FFF;
  font-family: "Montserrat";
  font-size: 23px;
  font-weight: 600;
  background: #76767C;
  cursor: pointer;
  padding: 15px 40px 15px 40px;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.spbgu-cookie-form .cookie-block span .form__submit-bt:hover {
  background: #CA0610;
}

@media (max-width: 991px) {
  .spbgu-cookie-form .cookie-block {
    padding: 18px 20px 18px 20px;
    gap: 5px;
  }
}
@media (max-width: 767px) {
  .spbgu-cookie-form .cookie-block {
    flex-direction: row;
    align-items: center;
  }
  .spbgu-cookie-form .cookie-block span {
    font-size: 10px;
  }
  .spbgu-cookie-form .cookie-block span .form__submit-bt {
    font-size: 16px;
    font-weight: 600;
    background: #76767C;
    cursor: pointer;
    padding: 6px 25px 7px 25px;
  }
}
@media (max-width: 325px) {
  .spbgu-cookie-form .cookie-block {
    flex-direction: column;
    align-items: flex-start;
  }
}
.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 43px;
  z-index: 50;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
}

.header-container {
  display: flex;
  flex-direction: row;
  min-height: 97px;
}

._container-header {
  display: flex;
  justify-content: space-between;
  max-width: 2050px;
  padding: 0 0 0 100px;
  margin: 0 auto;
  box-sizing: border-box;
}

.header-container__logo {
  display: flex;
  align-items: center;
  position: relative;
  border-left: 7px solid #CA0610;
  border-bottom: 7px solid #CA0610;
  width: 100%;
  box-sizing: content-box;
}

.header-container__logo-icon {
  position: absolute;
  left: -95px;
  height: auto;
}

.header-container__logo-icon--show {
  display: initial;
}

.header-container__logo-icon--hide {
  display: none;
}

.header-container__logo-icon--add-class {
  width: 75px;
}

.header-container__logo-icon--add-hover-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  content: "";
  position: absolute;
  background-color: #CA0610;
  width: 102px;
  height: 99px;
  left: -107px;
  bottom: -7px;
  z-index: 4;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translateX(90%);
  -moz-transform: translateX(90%);
  -ms-transform: translateX(90%);
  transform: translateX(90%);
}

.header-container:hover .header-container__logo-icon--add-hover-logo {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.header-container__logo-icon--add-hover-logo img {
  width: 75px;
}

.header-container__spbu-name {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-weight: 700;
  margin-left: 16px;
  color: #FFFFFF;
}

.header-border {
  position: relative;
}

.header-border::after {
  content: "";
  position: absolute;
  background-color: #CA0610;
  width: 65%;
  height: 7px;
  right: 0;
  bottom: 0;
}

.spbutopbar-flex#spbutopbar {
  z-index: 77;
  position: fixed;
  padding: 5px 20px;
  max-height: 43px;
  background: #333;
  line-height: 11px;
  font-size: 10px;
  width: 100%;
  left: 0;
}

.spbutopbar-flex#spbutopbar .spbutopbar-logotype {
  background-image: none;
}

.navi-ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-bottom: 7px solid #CA0610;
  padding-right: 50px;
}

.navi-li {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 23px;
  height: 100%;
  white-space: nowrap;
}

.navi-li:hover {
  background-color: #CA0610;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.navi-li-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #FFFFFF;
  font-size: 20px;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 600;
}

.navi-li--active {
  background-color: #CA0610;
}

.navi-li-search {
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 50px;
  z-index: 3;
}

.navi-li-close {
  display: none;
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 50px;
  z-index: 3;
}

.navi-li--not-active {
  pointer-events: none;
}

.navi-li-search__icon {
  position: absolute;
  flex-shrink: 0;
  right: -12px;
  top: 5px;
}

.header-container__search-burger-mobile {
  display: none;
}

.burger-menu {
  position: fixed;
  width: 390px;
  height: 542px;
  top: 0;
  left: 0;
  transform: translate(-100%);
}

.burger-menu.active {
  transform: scale(1);
  z-index: 4;
  opacity: 1;
  pointer-events: all;
  transition: 0.5s;
}

.blur {
  left: 30%;
  backdrop-filter: blur(0);
  position: absolute;
}

.burger-menu__content {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transform: scale(1);
}

.burger-menu__content-wrap {
  display: flex;
  gap: 10px;
  flex-direction: column;
  background-color: #FFFFFF;
}

.burger-menu__content-first-menu-container {
  display: flex;
  flex-direction: column;
  background-color: #1D1E25;
}

.burger-menu__content-close {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 12px 12px 40px 0;
}

.burger-menu__content-close-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.burger-menu__content-first-menu-block {
  display: flex;
  flex-direction: column;
  padding: 0 60px 65px 60px;
  gap: 35px;
}

.burger-menu__content-first-menu-item {
  font-size: 24px;
  color: #FFFFFF;
  cursor: pointer;
  line-height: normal;
  font-weight: 600;
  white-space: nowrap;
}

.burger-menu__content-first-menu-item:hover,
.burger-menu__content-second-menu-item:hover {
  color: #93CFBD;
}

.burger-menu__content-second-menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 35px;
  padding: 65px 60px 75px 60px;
  background-color: #1D1E25;
}

.burger-menu__content-second-menu-block {
  display: flex;
  gap: 35px;
  flex-direction: column;
}

.burger-menu__content-second-menu-item {
  line-height: 103%;
  font-weight: 300;
  font-size: 24px;
  color: #FFFFFF;
  cursor: pointer;
}

._container-header--with-sidebar {
  margin: 0 auto;
  padding: 0 0 0 150px;
}

@media (max-width: 1441px) {
  .header {
    top: 30px;
  }
  .header-container {
    min-height: 67px;
  }
  .spbutopbar-flex#spbutopbar {
    max-height: 30px;
    line-height: 100%;
    font-size: 8px;
  }
  .header-container__logo-icon--add-hover-logo {
    height: 69px;
  }
  .header-container__logo {
    margin-right: 0;
  }
  .header-container__logo-icon {
    width: 90px;
    left: -80px;
  }
  .header-container__logo-icon--add-class {
    width: 75px;
    left: -90px;
  }
  .header-container__spbu-name {
    font-size: 14px;
  }
  .navi-li {
    padding: 0 18px;
  }
  .navi-li-item {
    font-size: 16px;
  }
  .navi-li-search__icon {
    width: 30px;
    right: 0;
  }
}
@media (max-width: 991px) {
  .header-container:hover .header-container__logo-icon--add-hover-logo {
    opacity: 0;
    visibility: hidden;
  }
  .header {
    position: fixed;
  }
  ._container-header {
    padding: 0 0 0 60px;
  }
  ._container-header--with-sidebar {
    margin: 0 auto;
  }
  .header-container {
    align-items: center;
    justify-content: space-between;
    min-height: 45px;
    background-color: rgba(29, 30, 37, 0.46);
    backdrop-filter: blur(2.4375014305px);
  }
  .header-container__logo {
    border-left: 4px solid #CA0610;
    border-bottom: 4px solid #CA0610;
    width: 100%;
  }
  .header-border::after {
    height: 4px;
  }
  .header-container__logo {
    min-height: 45px;
  }
  .header-container__logo-icon {
    width: 55px;
    left: -50px;
    height: 30px;
  }
  .header-container__logo-icon--add-class {
    height: 15px;
    width: 40px;
    left: -55px;
  }
  .header-container__logo-icon--show {
    display: none;
  }
  .header-container__logo-icon--hide {
    display: initial;
  }
  .header-container__spbu-name {
    font-size: 10px;
    gap: 2px;
    margin-left: 8px;
    white-space: nowrap;
  }
  .header-container__search-burger-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-right: 12px;
  }
  .search-burger-mobile__icon-search {
    cursor: pointer;
    width: 30px;
    height: 20px;
  }
  .search-burger-mobile__icon-close {
    display: none;
    cursor: pointer;
    width: 35px;
    height: 35px;
  }
  .search-burger-mobile__icon-burger-btn {
    cursor: pointer;
    width: 27px;
    height: 27px;
  }
  .header-container__navi {
    display: none;
  }
  .header-burger-menu {
    width: 100%;
  }
  .header-container__search-burger-mobile::after {
    content: "";
    position: absolute;
    background-color: #CA0610;
    width: 20%;
    height: 4px;
    right: 0;
    bottom: 0;
  }
}
@media (max-width: 400px) {
  .blur {
    width: 100vw;
    height: 100vh;
  }
  .burger-menu {
    width: 100%;
  }
}
@media (max-width: 450px) {
  ._container-header {
    padding: 0 0 0 40px;
  }
  .burger-menu {
    width: 100%;
    height: 100vh;
  }
  .burger-menu__content {
    background-color: #1D1E25;
  }
  .header-container__spbu-name {
    font-size: 8px;
  }
  .header-container__logo-icon {
    width: 45px;
    left: -38px;
    height: 30px;
  }
  .header-container__logo-icon--add-class {
    height: 15px;
    width: 32px;
    left: -40px;
  }
  .header-container__search-burger-mobile {
    gap: 6px;
  }
  .search-burger-mobile__icon-burger-btn {
    width: 30px;
    height: 25px;
  }
  .search-burger-mobile__icon-search {
    width: 30px;
    height: 20px;
  }
}
@media (max-width: 325px) {
  .search-burger-mobile__icon-burger-btn {
    width: 20px;
    height: 20px;
  }
  .search-burger-mobile__icon-search {
    width: 25px;
    height: 20px;
  }
  .burger-menu__content-first-menu-item {
    white-space: pre-wrap;
  }
  .burger-menu__content-second-menu-block {
    flex-direction: column;
    gap: 35px;
  }
  .burger-menu__content-second-menu-container {
    flex-direction: column;
    gap: 35px;
  }
}
@media (max-height: 570px) {
  .burger-menu.active {
    overflow-x: auto;
  }
  .burger-menu {
    overflow: auto;
  }
  .burger-menu__content {
    height: 100vh;
    overflow: auto;
  }
}
.footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "news news media" "copy copy link";
  grid-template-rows: 1fr;
  width: 100%;
  position: relative;
}

.footer-news {
  grid-area: news;
  background-color: #1D1E25;
  display: flex;
  justify-content: flex-start;
  padding: 72px 120px 125px 100px;
  margin: 0 10px 10px 0;
}

.footer-news-form {
  display: flex;
  flex-direction: column;
  gap: 17px;
  width: 100%;
}

.footer-news-form-label {
  color: #FFFFFF;
  line-height: normal;
  font-weight: 600;
  font-size: 40px;
}

.footer-news-form-inputs-block {
  display: flex;
  align-items: center;
}

.footer-news-form-input-mail {
  outline: none;
  font-size: 15px;
  color: #1D1E25;
  height: 57px;
  width: 100%;
  padding: 0 20px;
}

.footer-news-form-input-mail::placeholder {
  color: #BDBDBD;
  font-size: 15px;
}

.footer-news-form-button {
  width: 120px;
  height: 57px;
  cursor: pointer;
  background-color: #93CFBD;
  color: #1D1E25;
  font-weight: 600;
  line-height: normal;
  font-size: 24px;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.footer-news-form-button:hover {
  background-color: #CA0610;
  color: #FFFFFF;
  transition: 0.3s ease all;
}

.footer-news .footer-news-form .footer-news-form-inputs-block .webform-ajax-form-wrapper {
  display: flex;
  width: 100%;
}

.footer-news .footer-news-form .footer-news-form-inputs-block .webform-ajax-form-wrapper .webform-submission-form {
  display: flex;
  width: 100%;
}

.footer-news .footer-news-form .footer-news-form-inputs-block .webform-ajax-form-wrapper .webform-submission-form .form-type-email {
  width: 100%;
}

.footer-social-media {
  grid-area: media;
  background-color: #1D1E25;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  padding: 42px 100px 40px 92px;
  margin: 0 0 10px 0;
}

.footer-social-media-item {
  color: #FFFFFF;
  font-size: 35px;
  line-height: normal;
  font-weight: 600;
}

.footer-social-media-item:hover {
  color: #93CFBD;
  transition: 0.3s ease all;
}

.footer-copy {
  display: grid;
  grid-template-columns: minmax(220px, 400px) 1fr 1fr;
  grid-template-areas: "copy copy link";
  grid-template-rows: 125px;
  width: 100%;
}

.footer-copyright-logo-name {
  display: flex;
  gap: 30px;
  align-items: center;
  grid-area: copy;
  background-color: #1D1E25;
  justify-content: flex-start;
  padding: 0 0 0 60px;
}

.footer-copyright-logo-name__logo {
  width: 44px;
  height: 52px;
  opacity: 0.7;
}

.footer-copyright-logo-name__spbu {
  display: flex;
  flex-direction: column;
  width: 843px;
}

.footer-copyright-logo-name__spbu p {
  font-size: 20px;
  font-weight: 300;
  line-height: normal;
  color: #BDBDBD;
}

.footer-copyright-link {
  grid-area: link;
  background-color: #1D1E25;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  padding: 42px 10px 40px 92px;
}

.footer-copyright-link p {
  line-height: normal;
  font-size: 20px;
  font-weight: 300;
  color: #BDBDBD;
  display: contents;
  white-space: nowrap;
}

.footer-copyright-link a {
  line-height: normal;
  font-size: 20px;
  font-weight: 300;
  color: #BDBDBD;
}

.footer-copyright-link a:hover {
  color: #93CFBD;
  transition: 0.3s ease all;
}

.footer-navigator {
  display: grid;
  grid-area: navigator;
  grid-template-columns: 78px 1fr;
  grid-template-rows: 459px;
  -webkit-transition-duration: 0.7s; /* анимационное выдвижение для всех браузеров*/
  -moz-transition-duration: 0.7s;
  -o-transition-duration: 0.7s;
  transition-duration: 0.7s;
}

.footer-navigator.active {
  width: fit-content;
  z-index: 4;
  opacity: 1;
  pointer-events: all;
  transform: translate(-80%);
  -webkit-transition-duration: 0.7s; /* анимационное выдвижение для всех браузеров*/
  -moz-transition-duration: 0.7s;
  -o-transition-duration: 0.7s;
  transition-duration: 0.7s;
}

.footer-navigator-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 26px;
  font-weight: 600;
  background-color: #93CFBD;
  white-space: nowrap;
  line-height: 133%;
  writing-mode: vertical-rl;
  transform: scale(-1);
  cursor: pointer;
  width: 100%;
  height: auto;
}

.footer-navigator-content {
  display: grid;
  grid-template-columns: 451px;
  grid-template-rows: auto 1fr;
  grid-template-areas: "sections-title sections-links" "menu sections-links";
  padding-right: 12px;
}

.footer-navigator-content-sections-title-wrap {
  grid-area: sections-title;
  display: flex;
  flex-direction: column;
}

.footer-navigator-content-sections-title-wrap .footer-navigator-content-sections-title-link:first-child {
  padding-top: 25px;
}

.footer-navigator-content-sections-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F4F4F4;
  font-size: 14px;
  color: #1D1E25;
  font-weight: 600;
  cursor: pointer;
  padding: 7px 25px 7px 25px;
}
.footer-navigator-content-sections-title:hover {
  color: #93CFBD;
  transition: 0.3s ease all;
}

.footer-navigator-content-sections-title-link {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  font-size: 14px;
  color: #1D1E25;
  font-weight: 600;
  cursor: pointer;
  padding: 7px 25px 7px 25px;
}
.footer-navigator-content-sections-title-link:hover {
  color: #93CFBD;
  transition: 0.3s ease all;
}

.footer-navigator-content-sections-title.active {
  color: #93CFBD;
}

.footer-navigator-content-sections {
  display: none;
  flex-direction: column;
  gap: 8px;
  grid-area: sections-links;
  background-color: #F4F4F4;
  padding: 25px 25px;
  position: absolute;
  right: 14px;
  height: 100%;
  width: 451px;
}

.footer-navigator-content-sections__item {
  color: #1D1E25;
  font-size: 14px;
  font-weight: 600;
  line-height: 133%;
}
.footer-navigator-content-sections__item:hover {
  color: #93CFBD;
  transition: 0.3s ease all;
}

.footer-navigator-content-menu-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-area: menu;
  background-color: #FFFFFF;
  padding: 6px 25px 25px 25px;
  height: 100%;
}

.footer-navigator-content-menu-links__item {
  color: #1D1E25;
  font-size: 14px;
  font-weight: 600;
  line-height: 133%;
}
.footer-navigator-content-menu-links__item:hover {
  color: #93CFBD;
  transition: 0.3s ease all;
}

@media (max-width: 1441px) {
  .footer-copyright-logo-name {
    justify-content: flex-start;
    padding: 20px 0 0 60px;
    gap: 30px;
  }
  .footer-copyright-logo-name__spbu p {
    font-size: 18px;
  }
  .footer-copyright-link p {
    font-size: 18px;
  }
  .footer-copyright-link a {
    font-size: 18px;
  }
}
@media (max-width: 1441px) {
  .footer {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .footer-navigator-content {
    grid-template-columns: 400px;
  }
  .footer-navigator-content-sections {
    width: 400px;
  }
  .footer-navigator-title {
    font-size: 22px;
  }
  .footer-news-form {
    width: 100%;
  }
  .footer-social-media {
    padding: 50px 50px;
  }
  .footer-news-form-label {
    font-size: 30px;
  }
  .footer-social-media-item {
    font-size: 26px;
  }
  .footer-copyright-logo-name__spbu p {
    font-size: 14px;
  }
  .footer-copyright-logo-name__spbu a {
    font-size: 14px;
    line-height: normal;
    font-weight: 300;
    color: #BDBDBD;
  }
  .footer-copyright-link {
    padding: 50px 50px;
  }
  .footer-copyright-link p {
    font-size: 14px;
  }
  .footer-copyright-link a {
    font-size: 14px;
  }
  .footer-copyright-logo-name__logo {
    width: 40px;
    height: 55px;
  }
  .footer-navigator {
    grid-template-columns: 68px 1fr;
  }
}
@media (max-width: 991px) {
  .footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "news news" "copy media" "copy media";
    grid-template-rows: auto auto auto;
    width: 100%;
  }
  .footer-news {
    justify-content: center;
    align-items: center;
    margin: 0 0 10px 0;
  }
  .footer-copyright-logo-name {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 42px 15px 15px 20px;
    gap: 22px;
    margin-right: 10px;
  }
  .footer-news-form-label {
    font-size: 24px;
  }
  .footer-news-form-input-mail {
    font-size: 15px;
    height: 43px;
    padding: 0 20px;
  }
  .footer-news-form-button {
    width: 64px;
    height: 43px;
    font-size: 16px;
  }
  .footer-copyright-logo-name__spbu {
    margin-right: 0;
    width: 100%;
  }
  .footer-copyright-logo-name__logo {
    width: 35px;
    height: 41px;
    margin-top: 11px;
  }
  .footer-copyright {
    flex-direction: column;
    gap: 12px;
    padding: 37px 23px 43px 20px;
    align-items: flex-start;
  }
  .footer-copyright-link {
    display: none;
  }
  .footer-copyright__link {
    margin-top: 16px;
    font-family: "Raleway";
    font-weight: 300;
    color: #BDBDBD;
    font-size: 14px;
  }
  .footer-copyright__link p {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  .footer-social-media {
    margin: 0;
    padding: 42px 20px 43px 27px;
  }
  .footer-social-media-item {
    font-size: 18px;
  }
  .footer-navigator-title {
    font-size: 18px;
    writing-mode: horizontal-tb;
    transform: scale(1);
  }
  .footer-navigator {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    width: 100%;
    transition-duration: 0s;
    -webkit-transition-duration: 0s;
  }
  .footer-navigator.active {
    transform: translateY(-455px);
    width: 100vw;
    z-index: 66;
  }
  .footer-navigator-title {
    height: 51px;
  }
  .footer-navigator-content {
    height: 100%;
    width: 100%;
    display: flex;
    visibility: hidden;
    flex-direction: column;
    padding: 0;
  }
  .footer-navigator-content-sections {
    gap: 12px;
    padding: 12px 16px 12px 45px;
    background-color: #FFFFFF;
    position: initial;
    width: 100%;
  }
  .footer-navigator-content-sections-title-img {
    transform: rotate(90deg);
  }
  .footer-navigator-content-sections-title.active .footer-navigator-content-sections-title-img {
    transform: rotate(-90deg);
  }
  .footer-navigator-content-sections__item {
    font-size: 12px;
  }
  .footer-navigator-content-sections-title-wrap .footer-navigator-content-sections-title-link:first-child {
    padding-top: 16px;
  }
  .footer-navigator-content-menu-links {
    gap: 10px;
    padding: 14px 20px;
  }
  .footer-navigator-content-sections-title {
    padding: 7px 20px 7px 20px;
  }
  .footer-navigator-content-sections-title-link {
    padding: 7px 20px 7px 20px;
  }
}
@media (max-width: 767px) {
  .footer {
    grid-template-columns: 60% 40%;
  }
  .footer-news {
    justify-content: center;
    align-items: center;
    padding: 37px 20px 49px 20px;
  }
  .footer-navigator-title {
    font-size: 16px;
  }
}
@media (max-width: 450px) {
  .footer-copyright-logo-name__spbu p {
    font-size: 12px;
  }
  .footer-copyright__link {
    font-size: 12px;
  }
  .footer-copyright__link a {
    font-size: 12px;
  }
}