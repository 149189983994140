//-----Контейнер для табов-переключателей форм-------------------------------------------------------------------------//
.forms-tab-wrap {
  display: flex;
  flex-direction: column;
}
.forms-tab__pos-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 57px;
}
.forms-tab__pos-row .forms-tab__item {
  width: 100%;
  margin-bottom: 0;
}
.forms-tab__menu-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 57px;
}
.forms-tab__menu-tabs-item {
  padding: 13px 37px 13px 37px;
  background-color: $color-smoke;
  font-size: $font-medium;
  line-height: 103%;
  font-weight: $font-weight-semi-bold;
  cursor: pointer;
  width: 100%;
  color: $color-black;
}
.forms-tab__menu-tabs-item._active {
  background-color: $color-mint;
  color: $color-white;
}
.forms-tab__level-tabs:not(._active) {
  display: none;
}
.forms-tab__level-tabs._active {
  margin-top: 80px;
}
.forms-tab__menu-tabs-item:hover {
  background-color: $color-mint;
  color: $color-white;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.forms-tab-wrap div:last-child {
  margin-bottom: 0;
}
.forms-tab__content {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.forms-tab__content:not(._active) {
  display: none;
}
.forms-tab__form {
  display: none;
}
.forms-tab__block-level-2 {
  display: none;
}
.forms-tab__block {
  margin-bottom: 30px;
}
.forms-tab__item {
  padding: 13px 37px 13px 37px;
  background-color: $color-smoke;
  font-size: $font-medium;
  line-height: 103%;
  font-weight: $font-weight-semi-bold;
  cursor: pointer;
  color: $color-black;
  margin-bottom: 30px;
}
.forms-tab__item._active {
  background-color: $color-mint;
  color: $color-white;
}
.forms-tab__item:hover {
  background-color: $color-mint;
  color: $color-white;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
@media (max-width: $adaptive-second-device) {
  .forms-tab__pos-row {
    flex-direction: column;
    gap: 30px;
  }
  .forms-tab__menu-tabs {
    flex-direction: column;
    gap: 30px;
  }
}
@media (max-width: $adaptive-mobile) {
  .forms-tab__item {
    padding: 20px 20px;
    font-size: $font-base-sx;
    margin-bottom: 24px;
  }
  .forms-tab__menu-tabs-item {
    padding: 20px 20px;
    font-size: $font-base-sx;
  }
}
@media (max-width: $adaptive-mobile-small) {
  .forms-tab__pos-row .forms-tab__item {
    padding: 20px 20px;
  }
  .forms-tab__menu-tabs {
    flex-direction: column;
    gap: 18px;
  }
  .forms-tab__level-tabs._active {
    margin-top: 50px;
  }
  .forms-tab__content {
    margin-top: 45px;
  }
}
@media (max-width: $adaptive-mobile-small-xs) {
  .forms-tab__item {
    padding: 20px 20px;
    font-size: $font-small-sx;
  }
  .forms-tab__menu-tabs-item {
    padding: 20px 20px;
    font-size: $font-small-sx;
  }
  .forms-tab__block {
    margin-bottom: 18px;
  }
  .forms-tab__menu-tabs {
    //margin-bottom: 45px;
  }
  .forms-tab__pos-row {
    gap: 18px;
  }
}
//-----Контейнер для элементов форм----------------------------------------------------------------------------------//
.forms-tab__form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.forms-tab__form .form-type-textfield {
  margin-bottom: 45px;
}
.forms-tab__form .form-type-email {
  margin-bottom: 45px;
}
.forms-tab__form .form-type-url {
  margin-bottom: 45px;
}
.forms-tab__form .form-type-url .form-url {
  outline: none;
  background-color: #F4F4F4;
  color: #76767C;
  font-family: Raleway;
  font-size: 30px;
  font-weight: 300;
  line-height: 103.023%;
  padding: 15px 40px;
  width: 100%;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius:0;
}
.forms-tab__form .form-type-email .form-email {
  outline: none;
  background-color: #F4F4F4;
  color: #76767C;
  font-family: Raleway;
  font-size: 30px;
  font-weight: 300;
  line-height: 103.023%;
  padding: 15px 40px;
  width: 100%;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius:0;
}
.webform-submission-form .form-type-email .form-email {
  background-color: #F4F4F4;
  //font-size: 30px;
  color: #76767C;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius:0;
}
.forms-tab__form .form-type-textfield .form-text {
  outline: none;
  background-color: #F4F4F4;
  color: #76767C;
  font-family: Raleway;
  font-size: 30px;
  font-weight: 300;
  line-height: 103.023%;
  padding: 15px 40px;
  width: 100%;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius:0;
}
.forms-tab__form .form-type-textarea {
  margin-bottom: 45px;
}
.forms-tab__form .form-type-textarea .form-textarea {
  width: 100%;
  line-height: 103%;
  font-family: Raleway;
  font-weight: 300;
  font-size: 30px;
  color: #76767C;
  height: 229px;
  resize: none;
  outline: none;
  background-color: #F4F4F4;
  padding: 15px 40px;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius:0;
}
.forms-tab__form .form-actions {
  display: flex;
  justify-content: flex-end;
}
.forms-tab__form .form-actions .webform-button--submit {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  line-height: 103.023%;
  color: #FFFFFF;
  background: #76767C;
  padding: 12px 87px 12px 87px;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius:0;
}
.forms-tab__form .form-actions .webform-button--submit:hover {
  background: #CA0610;
}
.webform-submission-podpiska-na-rassylku-form .form-type-email {
  float: left;
  width: 80%;
  max-width: 100%;
}
.webform-submission-podpiska-na-rassylku-form .webform-actions {
  display: flex;
}
.webform-submission-podpiska-na-rassylku-form .webform-actions .webform-button--submit {
  width: 100%;
}
.webform-submission-form .js-webform-states-hidden {
  padding: 0 0 45px 0;
}
/*------Чекбокс-------------------------------------------------------------------------------------------------------*/
.forms-tab__form .form-type-checkbox {
  margin-bottom: 25px;
  position: relative;
}
.forms-tab__form .form-type-checkbox .form-checkbox {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
}
.forms-tab__form .form-type-checkbox .option {
  color: #76767C;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 103.023%;
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 0.4% 16px 0.4% 48px;
  z-index: 3;
  overflow: hidden;
}
input:checked ~ .checkmark::after {
  display: block;
  background-color: #CA0610;
}
input:checked ~ .checkmark {
  background-color: #D9D9D9;
}
.forms-tab__form .form-type-checkbox .checkmark:after {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #CA0610;
  cursor: pointer;
  border: none;
  transform: initial;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius:0;
}
.forms-tab__form .form-type-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #D9D9D9;
  cursor: pointer;
  transform: initial;
  border: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius:0;
}
/*------Группа чекбоксов-------------------------------------------------------------------------------------------------*/
.forms-tab__form .checkboxes--wrapper {
  margin-bottom: 45px;
}
.forms-tab__form .checkboxes--wrapper .fieldset-legend {
  color: #76767C;
  line-height: 155%;
  font-size: 20px;
}
.forms-tab__form .fieldset-wrapper .webform-options-display-two-columns {
  display: block;
  max-width: 100%;
  margin-top: 0.4em;
  margin-bottom: 0.5em;
  column-count: 4;
}
/*------Сообщение об ошибке-------------------------------------------------------------------------------------------*/
.forms-tab__form .form-type-email .form-item--error-message {
  color: #CA0610;
  margin-top: 8px;
  font-size: 14px;
}
.forms-tab__form .form-item--error-message {
  color: #CA0610;
  margin-top: 8px;
  font-size: 14px;
}
/*------Форма телефон-------------------------------------------------------------------------------------------------*/
.forms-tab__form .webform-telephone--wrapper {
  margin-bottom: 45px;
}
.forms-tab__form .webform-telephone--wrapper .fieldset-legend {
  color: #76767C;
  line-height: 155%;
  font-size: 20px;
}
.forms-tab__form .webform-telephone--wrapper .form-type-tel .form-tel {
  outline: none;
  background-color: #F4F4F4;
  color: #76767C;
  font-family: Raleway;
  font-size: 30px;
  font-weight: 300;
  line-height: 103.023%;
  padding: 15px 40px 15px 50px;
  width: 100%;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius:0;
}
/*------Форма дата----------------------------------------------------------------------------------------------------*/
.forms-tab__form .form-type-datetime {
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
}
.forms-tab__form .form-type-datetime .form-required {
  color: #76767C;
  line-height: 155%;
  font-size: 20px;
  margin-right: 12px;
}
.forms-tab__form .form-type-datetime .form-type-date .form-date {
  outline: none;
  background-color: #F4F4F4;
  color: #76767C;
  font-family: Raleway;
  font-size: 30px;
  font-weight: 300;
  line-height: 103.023%;
  padding: 15px 15px;
  width: 100%;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius:0;
}
.forms-tab__form .form-type-webform-time .form-time {
  outline: none;
  background-color: #F4F4F4;
  color: #76767C;
  font-family: Raleway;
  font-size: 30px;
  font-weight: 300;
  line-height: 103.023%;
  padding: 15px 15px;
  //width: 100%;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius:0;
}
/*------Форма файл---------------------------------------------------------------------------------------------------*/
.forms-tab__form .form-type-managed-file {
  margin-bottom: 45px;
}
.forms-tab__form .form-type-managed-file label {
  background-color: #76767C;
  color: #FFFFFF;
  font-size: 32px;
  font-weight: 600;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 56px;
  max-width: 435px;
  margin-bottom: 15px;
}
.forms-tab__form .form-type-managed-file label:hover {
  background-color: #CA0610;
}
.forms-tab__form .form-type-managed-file .form-managed-file {
  margin-bottom: 15px;
}
.forms-tab__form .form-type-managed-file .description {
  color: #76767C;
  line-height: 135%;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 25px;
}
/*------Форма радиочекбокс---------------------------------------------------------------------------------------------*/
.forms-tab__form .fieldset-wrapper .webform-options-display-buttons {
  display: flex;
  gap: 10px;
}
.forms-tab__form .fieldset-wrapper .webform-options-display-buttons .webform-options-display-buttons-wrapper {
  cursor: pointer;
  color: #76767C;
  font-size: 24px;
  border-color: #76767C;
  margin: 0;
}
.forms-tab__form .fieldset-wrapper .webform-options-display-buttons .webform-options-display-buttons-label {
  display: table-cell;
  height: 100%;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  color: #76767C;
  border: 1px solid #76767C;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius:0;
  background-color: #fff;
}
.forms-tab__form .fieldset-wrapper .webform-options-display-buttons input:checked + label.webform-options-display-buttons-label {
  color: #93CFBD;
  border-color: #93CFBD;;
}
.forms-tab__form .checkboxes--wrapper .fieldset-legend {
  color: #76767C;
  line-height: 155%;
  font-size: 20px;
}
.forms-tab__form  .fieldset-wrapper .form-type-checkbox {
  margin-bottom: 25px;
}
.forms-tab__form  .fieldset-wrapper {
  margin-bottom: 25px;
}
.webform-options-display-buttons input:checked:focus + label.webform-options-display-buttons-label {
  box-shadow: none;
}
.webform-options-display-buttons input:checked + label.webform-options-display-buttons-label {
  color: #93CFBD;
  border-color: #93CFBD;
}
.webform-options-display-buttons input:hover + label.webform-options-display-buttons-label {
  color: #93CFBD;
  border-color: #93CFBD;
}
@media (max-width: $adaptive-mobile) {
  .webform-submission-podpiska-na-rassylku-form .webform-actions .webform-button--submit {
    width: 100%;
  }
  .forms-tab__form .fieldset-wrapper .webform-options-display-two-columns {
    column-count: 2;
  }
}
@media (max-width: $adaptive-mobile-small) {
  .forms-tab__form .form-type-textfield .form-text {
    padding: 10px 16px;
    font-size: 15px;
  }
  .forms-tab__form .form-type-email .form-email {
    padding: 10px 16px;
    font-size: 15px;
  }
  .webform-submission-form .form-type-email .form-email {
    padding: 10px 16px;
    font-size: 15px;
  }
  .forms-tab__form .form-type-url .form-url {
    padding: 10px 16px;
    font-size: 15px;
  }
  .forms-tab__form  .webform-telephone--wrapper .form-type-tel .form-tel {
    padding: 10px 16px 10px 45px;
    font-size: 15px;
  }
  .forms-tab__form .form-type-datetime .form-type-date .form-date {
    font-size: 15px;
    padding: 10px 10px;
  }
  .forms-tab__form .form-type-webform-time .form-time {
    font-size: 15px;
    padding: 10px 10px;
  }
  .forms-tab__form .form-type-textarea .form-textarea {
    padding: 10px 16px;
    font-size: 15px;
    height: 147px;
  }
  .forms-tab__form .form-actions .webform-button--submit {
    width: 100%;
    padding: 10px 10px;
    font-size: 18px;
  }
  .forms-tab__form .form-type-managed-file label {
    max-width: 100%;
    padding: 10px 10px;
    font-size: 18px;
    height: auto;
    margin-bottom: 15px;
  }
  .forms-tab__form .webform-telephone--wrapper .fieldset-legend {
    font-size: 12px;
  }
  .forms-tab__form .checkboxes--wrapper .fieldset-legend {
    font-size: 12px;
  }
  .forms-tab__form .form-type-datetime .form-required {
    font-size: 12px;
  }
  .forms-tab__form .form-type-textfield {
    margin-bottom: 15px;
  }
  .forms-tab__form .form-type-email {
    margin-bottom: 15px;
  }
  .forms-tab__form .form-type-url {
    margin-bottom: 15px;
  }
  .forms-tab__form  .webform-telephone--wrapper {
    margin-bottom: 15px;
  }
  .forms-tab__form .form-type-datetime {
    margin-bottom: 15px;
  }
  .forms-tab__form .checkboxes--wrapper {
    margin-bottom: 15px;
  }
  .forms-tab__form  .fieldset-wrapper .form-type-checkbox {
    margin-bottom: 15px;
  }
  .forms-tab__form  .fieldset-wrapper {
    margin-bottom: 15px;
  }
  .forms-tab__form .form-type-textarea {
    margin-bottom: 15px;
  }
  .forms-tab__form .form-type-managed-file .description {
    margin-bottom: 15px;
  }
  .forms-tab__form .form-type-datetime .form-type-date {
    margin-bottom: 15px;
  }
  .forms-tab__form .form-type-checkbox .option {
    padding: 0.5% 0 0.4% 37px;
  }
  .forms-tab__form .form-type-radio .option {
    padding: 0.5% 0 0.4% 37px;
  }
  .forms-tab__form .form-type-managed-file .description {
    font-size: 11px;
    margin-top: 4px;
  }
  .forms-tab__form .radios--wrapper .fieldset-wrapper .webform-options-display-buttons {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
  .forms-tab__form .fieldset-wrapper .webform-options-display-buttons .webform-options-display-buttons-wrapper {
    font-size: 15px;
  }
  .forms-tab__form .form-item--error-message {;
    font-size: 10px;
  }
  .forms-tab__form .form-type-email .form-item--error-message {
    font-size: 10px;
  }
  .forms-tab__form .form-type-radio .option {
    padding: 20px 20px;
    font-size: 15px;
  }
  .webform-submission-form .form-type-email {
    float: left;
    max-width: 100%;
    width: 100%;
    margin-bottom: 15px;
  }
  .forms-tab__form .webform-submission-form .form-actions .webform-button--submit {
    width: 100%;
  }
  .webform-submission-form .checkboxes--wrapper .webform-options-display-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
  }
  .webform-submission-form .checkboxes--wrapper .webform-options-display-buttons .webform-options-display-buttons-wrapper {
    display: block;
    margin: 5px 5px;
  }
  .webform-submission-form .js-webform-states-hidden {
    padding: 0 0 15px 0;
  }
}
@media (max-width: $adaptive-mobile-small-xs) {
  .forms-tab__form .form-actions .webform-button--submit {
    font-size: 13px;
  }
  .forms-tab__form .form-type-managed-file label {
    font-size: 13px;
  }
  .forms-tab__form .form-type-checkbox .option {
    font-size: 10px;
    padding: 0.5% 0 0.4% 37px;
  }
  .forms-tab__form .form-type-checkbox .checkmark:after {
    height: 18px;
    width: 18px;
  }
  .forms-tab__form .form-type-checkbox .checkmark {
    height: 18px;
    width: 18px;
    top: 3px;
  }
  .webform-submission-form .checkboxes--wrapper .webform-options-display-buttons .webform-options-display-buttons-wrapper .form-type-checkbox .webform-options-display-buttons-label option {
    display: table-cell;
    height: 100%;
    padding: 10px 5px 10px 5px;
    text-align: center;
    vertical-align: middle;
    color: #666;
    border: 3px solid #ccc;
    border-top-color: rgb(204, 204, 204);
    border-right-color: rgb(204, 204, 204);
    border-bottom-color: rgb(204, 204, 204);
    border-left-color: rgb(204, 204, 204);
    border-radius: 3px;
    background-color: #fff;
    font-size: 12px;
  }
}
