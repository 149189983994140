//-----Card-person(person-large)---------------------------------------------------------------------------//
.card-person-large {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'Raleway';
  gap: 24px;
}
.card-person-large__name-block {
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 24px;
  cursor: default;
}
.card-person-large__name-person {
  color: rgba(29, 30, 37, 0.85);
  font-size: $font-special;
  font-style: normal;
  font-weight: 300;
  line-height: 103%;
  word-wrap: break-word;
}
.card-person-large__job-title {
  font-size: $font-base-m;
  font-weight: $font-weight-light;
  line-height: 103%;
  color: $color-darkgray;
  word-wrap: break-word;
}
.card-person-large__achievements {
  display: initial;
  width: 60%;
  font-size: $font-base-x;
  color: $color-darkgray;
  line-height: 135%;
  font-weight: $font-weight-light;
  word-wrap: break-word;
}
.card-person-large__media {
  display: none;
  width: 60%;
  position: relative;
  justify-content: center;
}
.card-person-large__image {
  width: 100%;
  //max-width: 477px;
  height: auto;
  object-fit: contain;
  object-position: center;
  background-repeat: no-repeat;
  //position: absolute;
  //bottom: 0;
}
@media (max-width: $adaptive-high-device) {
  .card-person-large__name-block {
    width: 40%;
  }
}
@media (max-width: $adaptive-second-device) {
  .card-person-large__name-block {
    gap: 20px;
  }
  .card-person-large__image {
    max-width: 533px;
  }
}
@media (max-width: $adaptive-ipad) {
  .card-person-large {
    flex-direction: column;
    gap: 18px;
  }
  .card-person-large__media {
    display: flex;
    order: 1;
    width: 100%;
    justify-content: center;
  }
  .card-person-large__image {
    height: auto;
    position: initial;
    //max-width: 700px;
    max-width: 100%;
  }
  .card-person-large__name-block {
    order: 2;
    width: 100%;
  }
  .card-person-large__achievements {
    order: 3;
    width: 100%;
  }
  .card-person-large__name-person {
    font-size: $font-medium-x;
  }
  .card-person-large__job-title {
    font-size: $font-base-s;
  }
  .card-person-large__achievements {
    font-size: $font-base-sx;
  }
}
@media (max-width: $adaptive-mobile-small) {
  .card-person-large__name-person {
    font-size: $font-medium-x;
  }
  .card-person-large__job-title {
    font-size: $font-base-s;
  }
  .card-person-large__achievements {
    font-size: $font-base-sx;
  }
}
@media (max-width: $adaptive-mobile-small-xs) {
  .card-person-large__name-person {
    font-size: $font-base-sx;
    line-height: 129%;
  }
  .card-person-large__job-title {
    font-size: $font-small-sx;
  }
  .card-person-large__achievements {
    font-size: $font-small-s;
  }
}
//-----Card-person(person-small)---------------------------------------------------------------------------//
.card-person-small {
  display: flex;
  flex-direction: column;
  font-family: 'Raleway';
  gap: 34px;
}
.card-person-small__name-person {
  color: $color-darkgray;
  line-height: 129%;
  font-weight: $font-weight-light;
  font-size: $font-medium-s;
  word-wrap: break-word;
  cursor: default;
}
.card-person-small__job-title {
  line-height: 103%;
  font-weight: $font-weight-light;
  color: $color-darkgray;
  font-size: $font-small-s;
  word-wrap: break-word;
}
.card-person-small__achievements {
  line-height: 103%;
  font-weight: $font-weight-light;
  color: $color-darkgray;
  font-size: $font-base-s;
  word-wrap: break-word;
}
.card-person-small__media {
  display: none;
  width: 100%;
  position: relative;
}
.card-person-small__image {
  width: 100%;
  max-width: 477px;
  height: auto;
  object-fit: contain;
  object-position: center;
  background-repeat: no-repeat;
  //position: absolute;
  bottom: 0;
}
@media (max-width: $adaptive-second-device) {
  .card-person-small {
    gap: 30px;
  }
}
@media (max-width: $adaptive-ipad) {
  .card-person-small {
    gap: 18px;
  }
  .card-person-small__media {
    display: flex;
    order: 1;
  }
  .card-person-small__image {
    height: auto;
    position: initial;
  }
  .card-person-small__name-person {
    order: 2;
    font-size: $font-base-x;
  }
  .card-person-small__job-title {
    order: 3;
    font-size: $font-small-sx;
  }
  .card-person-small__achievements {
    order: 4;
    font-size: $font-base;
  }
}
@media (max-width: $adaptive-mobile-small-xs) {
  .card-person-small__image {
    max-width: 200px;
  }
  .card-person-small__name-person {
    font-size: $font-base;
  }
  .card-person-small__job-title {
    font-size: $font-small-x;
  }
  .card-person-small__achievements {
    font-size: $font-small;
  }
}