//-----Шапка сайта------------------------------------------------------------------------------//
.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 43px;
  z-index: 50;
  background: rgba(0,0,0, 0.5);
  //max-width: 2050px;
  backdrop-filter: blur(3px);
}
.header-container {
  display: flex;
  flex-direction: row;
  min-height: 97px;
}
._container-header {
  display: flex;
  justify-content: space-between;
  max-width: 2050px;
  padding: 0 0 0 100px;
  margin: 0 auto;
  box-sizing: border-box;
}
.header-container__logo {
  display: flex;
  align-items: center;
  position: relative;
  border-left: 7px solid $color-red;
  border-bottom: 7px solid $color-red;
  width: 100%;
  box-sizing: content-box;
  //margin-right: 40px;
}
.header-container__logo-icon {
  position: absolute;
  left: -95px;
  height: auto;
}
.header-container__logo-icon--show {
  display: initial;
}
.header-container__logo-icon--hide {
  display: none;
}
.header-container__logo-icon--add-class {
  width: 75px;
}
.header-container__logo-icon--add-hover-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  content: "";
  position: absolute;
  background-color: #CA0610;
  width: 102px;
  height: 99px;
  left: -107px;
  bottom: -7px;
  z-index: 4;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-transform: translateX(90%);
  -moz-transform: translateX(90%);
  -ms-transform: translateX(90%);
  transform: translateX(90%);
}
.header-container:hover {
  & .header-container__logo-icon--add-hover-logo {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
  }
}
.header-container__logo-icon--add-hover-logo img {
  width: 75px;
}
.header-container__spbu-name {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-weight: 700;
  margin-left: 16px;
  color: $color-white;
}
.header-border {
  position: relative;
}
.header-border::after {
  content: '';
  position: absolute;
  background-color: $color-red;
  width: 65%;
  height: 7px;
  right: 0;
  bottom: 0;
}
//----Панель СпбГУ--------------------------------------------------------------------------------//
.spbutopbar-flex#spbutopbar {
  z-index: 77;
  position: fixed;
  padding: 5px 20px;
  max-height: 43px;
  background: #333;
  line-height: 11px;
  font-size: 10px;
  width: 100%;
  left: 0;
  //max-width: 2050px;
}
.spbutopbar-flex#spbutopbar .spbutopbar-logotype {
  background-image: none;
}
//------Блок с навигацией-------------------------------------------------------------------------//
.navi-ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-bottom: 7px solid $color-red;
  padding-right: 50px;
}
.navi-li {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 23px;
  height: 100%;
  white-space: nowrap;
}
.navi-li:hover {
  background-color: $color-red;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.navi-li-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: $color-white;
  font-size: $font-base-s;
  line-height: normal;
  text-transform: uppercase;
  font-weight: $font-weight-semi-bold;
}
.navi-li--active {
  background-color: $color-red;
}
.navi-li-search {
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 50px;
  z-index: 3;
}
.navi-li-close {
  display: none;
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 50px;
  z-index: 3;
}
.navi-li--not-active {
  pointer-events: none;
}
.navi-li-search__icon {
  position: absolute;
  flex-shrink: 0;
  right: -12px;
  //right: -15px;
  top: 5px;
}
//------Блок с элементами для мобильной версии----------------------------------------------------//
.header-container__search-burger-mobile {
  display: none;
}
//-------Основной стиль для бургер-меню------------------------------------------------------------//
.burger-menu {
  position: fixed;
  width: 390px;
  height: 542px;
  top: 0;
  left: 0;
  transform: translate(-100%);
}
.burger-menu.active {
  transform: scale(1);
  z-index: 4;
  opacity: 1;
  pointer-events: all;
  transition: 0.5s;
}
.blur {
  left: 30%;
  backdrop-filter: blur(0);
  position: absolute;
}
//-------Стилизация для контента бургер-меню-----------------------------------------------------------//
.burger-menu__content {
  width: 100%;
  height: 100%;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transform: scale(1);
}
.burger-menu__content-wrap {
  display: flex;
  gap: 10px;
  flex-direction: column;
  background-color: $color-white;
}
.burger-menu__content-first-menu-container {
  display: flex;
  flex-direction: column;
  background-color: $color-black;
}
.burger-menu__content-close {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 12px 12px 40px 0;
}
.burger-menu__content-close-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
}
.burger-menu__content-first-menu-block {
  display: flex;
  flex-direction: column;
  padding: 0 60px 65px 60px;
  gap: 35px;
}
.burger-menu__content-first-menu-item {
  font-size: $font-base-sx;
  color: $color-white;
  cursor: pointer;
  line-height: normal;
  font-weight: $font-weight-semi-bold;
  white-space: nowrap;
}
.burger-menu__content-first-menu-item:hover,
.burger-menu__content-second-menu-item:hover {
  color: $color-mint;
}
.burger-menu__content-second-menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 35px;
  padding: 65px 60px 75px 60px;
  background-color: $color-black;
}
.burger-menu__content-second-menu-block {
  display: flex;
  gap: 35px;
  flex-direction: column;
}
.burger-menu__content-second-menu-item {
  line-height: 103%;
  font-weight: $font-weight-light;
  font-size: $font-base-sx;
  color: $color-white;
  cursor: pointer;
}
._container-header--with-sidebar {
  margin: 0 auto;
  padding: 0 0 0 150px;
}
//------Адаптивная версия-------------------------------------------------------------------------//
@media (max-width: $adaptive-large-device) {
  // ._container-header {
  //   padding: 0 100px;
  // }
  //._container-header--with-sidebar {
  //  margin: 0 auto 0 50px;
  //}
}
@media (max-width: $adaptive-second-device) {
  .header {
    top: 30px;
  }
  .header-container {
    min-height: 67px;
  }
  .spbutopbar-flex#spbutopbar {
    max-height: 30px;
    line-height: 100%;
    font-size: 8px;
  }
  .header-container__logo-icon--add-hover-logo {
     height: 69px;
  }
  .header-container__logo {
    margin-right: 0;
  }
  .header-container__logo-icon {
    width: 90px;
    left: -80px;
  }
  .header-container__logo-icon--add-class {
    width: 75px;
    left: -90px;
  }
  .header-container__spbu-name {
    font-size: $font-small-sx;
  }
  .navi-li {
    padding: 0 18px;
  }
  .navi-li-item {
    font-size: $font-small-s;
  }
  .navi-li-search__icon {
    width: 30px;
    right: 0;
  }
}
@media (max-width: $adaptive-mobile) {
  .header-container:hover {
    & .header-container__logo-icon--add-hover-logo {
      opacity: 0;
      visibility: hidden;
    }
  }
  .header {
    position: fixed;
  }
  ._container-header {
    padding: 0 0 0 60px;
  }
  ._container-header--with-sidebar {
    margin: 0 auto;
  }
  .header-container {
    align-items: center;
    justify-content: space-between;
    min-height: 45px;
    background-color: rgba(29, 30, 37, 0.46);
    backdrop-filter: blur(2.4375014305114746px);
  }
  .header-container__logo {
    border-left: 4px solid $color-red;
    border-bottom: 4px solid $color-red;
    width: 100%;
  }
  .header-border::after {
    height: 4px;
  }
  .header-container__logo {
    min-height: 45px;
  }
  .header-container__logo-icon {
    width: 55px;
    left: -50px;
    height: 30px;
  }
  .header-container__logo-icon--add-class {
    height: 15px;
    width: 40px;
    left: -55px;
  }
  .header-container__logo-icon--show {
    display: none;
  }
  .header-container__logo-icon--hide {
    display: initial;
  }
  .header-container__spbu-name {
    font-size: 10px;
    gap: 2px;
    margin-left: 8px;
    white-space: nowrap;
  }
  .header-container__search-burger-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-right: 12px;
  }
  .search-burger-mobile__icon-search {
    cursor: pointer;
    width: 30px;
    height: 20px;
  }
  .search-burger-mobile__icon-close {
    display: none;
    cursor: pointer;
    width: 35px;
    height: 35px;
  }
  .search-burger-mobile__icon-burger-btn {
    cursor: pointer;
    width: 27px;
    height: 27px;
  }
  .header-container__navi {
    display: none;
  }
  .header-burger-menu {
    width: 100%;
  }
  .header-container__search-burger-mobile::after {
    content: '';
    position: absolute;
    background-color: $color-red;
    width: 20%;
    height: 4px;
    right: 0;
    bottom: 0;
  }
}
@media (max-width: 400px) {
  .blur {
    width: 100vw;
    height: 100vh;
  }
  .burger-menu {
    width: 100%;
  }
}
@media (max-width: $adaptive-mobile-small-xs) {
  ._container-header {
    padding: 0 0 0 40px;
  }
  .burger-menu {
    width: 100%;
    height: 100vh;
  }
  .burger-menu__content {
    background-color: $color-black;
  }
  .header-container__spbu-name {
    font-size: 8px;
  }
  .header-container__logo-icon {
    width: 45px;
    left: -38px;
    height: 30px;
  }
  .header-container__logo-icon--add-class {
    height: 15px;
    width: 32px;
    left: -40px;
  }
  .header-container__search-burger-mobile {
    gap: 6px;
  }
  .search-burger-mobile__icon-burger-btn {
    width: 30px;
    height: 25px;
  }
  .search-burger-mobile__icon-search {
    width: 30px;
    height: 20px;
  }
}
@media (max-width: $adaptive-mobile-ultra-small) {
  .search-burger-mobile__icon-burger-btn {
    width: 20px;
    height: 20px;
  }
  .search-burger-mobile__icon-search {
    width: 25px;
    height: 20px;
  }
  .burger-menu__content-first-menu-item {
    white-space: pre-wrap;
  }
  .burger-menu__content-second-menu-block {
    flex-direction: column;
    gap: 35px;
  }
  .burger-menu__content-second-menu-container {
    flex-direction: column;
    gap: 35px;
  }
}
@media (max-height: 570px) {
  .burger-menu.active {
    overflow-x: auto;
  }
  .burger-menu {
    overflow: auto;
  }
  .burger-menu__content {
    height: 100vh;
    overflow: auto;
  }
}
