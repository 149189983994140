//-----photo-repository---------------------------------------------------------------------------//
.photo-repository-wrap {
  display: flex;
  flex-direction: column;
}
.photo-repository-wrap > div:nth-child(2) {
  display: block;
}
.photo-repository-menu-title {
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-bottom: 70px;
}
.photo-repository__title {
  line-height: 103%;
  font-weight: $font-weight-semi-bold;
  font-size: $font-large;
  color: $color-black;
}
.photo-repository__menu {
  display: flex;
  flex-wrap: wrap;
  gap: 37px;
}
.photo-repository__menu-item {
  line-height: 103%;
  font-weight: $font-weight-semi-bold;
  font-size: $font-base-s;
  color: $color-light-gray;
  cursor: pointer;
}
.photo-repository__menu-item._active {
  color: $color-mint;
}
.photo-repository__menu-item:hover {
  color: $color-mint;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.photo-repository-download__bt {
  font-weight: $font-weight-light;
  font-size: $font-medium-s;
  margin-top: 20px;
  font-family: 'Raleway';
  color: $color-black;
  cursor: pointer;
}
.photo-repository-download__bt:hover {
  color: $color-mint;
}
.photo-repository-card-wrap .owl-nav {
  display: none;
}
.photo-repository-card-wrap .owl-carousel .owl-dots {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 18px;
  margin-top: 37px;
}
.photo-repository-card-wrap .owl-dots .owl-dot {
  height: 17px;
  width: 17px;
  background-color: $color-gray;
}
.photo-repository-card-wrap .owl-dots .owl-dot.active {
  height: 17px;
  width: 17px;
  background-color: $color-mint;
}
.photo-repository-modal-wrap._active {
  display:flex;
  align-items:center;
  justify-content:center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 30, 37, 0.90);
  z-index: 56;
}
.photo-repository-modal-wrap {
  display: none;
}
.photo-repository-modal {
  padding: 50px 20px 50px 20px;
  max-height: 100%;
}
.photo-repository-modal__close-btn {

}
.photo-repository-modal__close-btn:after {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  top: 20px;
  right: 50px;
  background-image: url("../../img/icons/CloseIconPhotoModal.svg");
  background-size: cover;
  cursor: pointer;
}
.photo-repository-modal .photo-repository-card__media {
  cursor: default;
}
.photo-repository-download__bt {
  display: none;
}
.fancybox__caption .photo-repository-download__bt {
  //margin-top: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  max-width: 160px;
}
.photo-repository-modal-wrap._active .photo-repository-modal .pure-img {
  max-width: 100%;
  max-height: 80vh;
  display: block;
}
.photo-repository-download--icon {
  width: 30px;
  height: 30px;
  background-image: url("../../img/icons/DownLoadIconWhite.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.photo-repository-download--link {
  font-weight: $font-weight-light;
  font-size: $font-medium-s;
  font-family: 'Raleway';
  color: $color-white;
  text-decoration: underline $color-white;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
}
.photo-repository-download__bt:hover {
  color: $color-mint;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  & .photo-repository-download--icon {
    background-image: url("../../img/icons/DownLoadIconActive.svg");
  }
  & .photo-repository-download--link {
    color: $color-mint;
    text-decoration: underline $color-mint;
  }
}
.fancybox__container .is-compact .fancybox__footer {
  position: relative;
}
.figcaption__block {
  display: flex;
  flex-direction: column;
}
.fancybox__container .fancybox-image {
  margin: auto;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 0;
  object-fit: cover;
  user-select: none;
}
figcaption {
  display: none;
}
.figcaption__block p {
  font-size: $font-small-s;
}
@media (max-width: $adaptive-special-device) {
  .photo-repository__menu {
    gap: 24px;
  }
}
@media (max-width: $adaptive-second-device) {
  .photo-repository__title {
    font-size: $font-special-x;
  }
  .photo-repository__menu-item {
    font-size: $font-base;
  }
}
@media (max-width: $adaptive-mobile) {
  .photo-repository-menu-title  {
    margin-bottom: 30px;
  }
  .photo-repository__menu {
    gap: 20px;
  }
  .photo-repository-modal__close-btn:after {
    right: 20px;
  }
  .photo-repository-download__bt {
    //margin-top: 30px;
  }
  .photo-repository-download--icon {
    width: 24px;
    height: 24px;
  }
  .photo-repository-download--link {
    font-size: $font-base-x;
  }
}
@media (max-width: $adaptive-mobile-small) {
  .photo-repository__title {
    font-size: $font-medium-sm;
  }
  .photo-repository__menu {
    gap: 16px;
  }
  .photo-repository__menu-item {
    font-size: $font-small;
  }
  .photo-repository-download__bt {
    gap: 6px;
  }
  .photo-repository-download--icon {
    width: 14px;
    height: 14px;
  }
  .photo-repository-download--link {
    font-size: $font-small-s;
  }
  .figcaption__block p {
    font-size: $font-small-sx;
  }
}
@media (max-width: $adaptive-mobile-small-xs) {
  .photo-repository-modal__close-btn:after {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
  .photo-repository-card-wrap .owl-carousel .owl-dots {
    margin-top: 25px;
    gap: 10px;
  }
  .photo-repository-card-wrap .owl-dots .owl-dot {
    height: 10px;
    width: 10px;
  }
  .photo-repository-card-wrap .owl-dots .owl-dot.active {
    height: 10px;
    width: 10px;
  }
  .photo-repository-download__bt {
    margin-top: 15px;
  }
}
//-----photo-repository-card---------------------------------------------------------------------------//
.photo-repository-card-wrap {
  display: none;
}
.photo-repository-card-wrap > div:last-child {
  margin-bottom: 0;
}
.photo-repository-card--color-gray {
  background-color: $color-darkgray;
}
.photo-repository-card--color-light-gray {
  background-color: $color-light-gray;
}
.photo-repository-card--color-red {
  background-color: $color-red;
}
.photo-repository-card--color-light-black {
  background-color: $color-light-black;
}
.photo-repository-card--color-mint {
  background-color: $color-mint;
}
.photo-repository-card__text {
  line-height: 133%;
  font-size: $font-base-s;
  color: $color-black;
}
.photo-repository-card__color-block {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 36px;
  padding: 50px 50px;
}
.photo-repository-card__block-text {
  font-size: $font-medium-s;
  color: $color-white;
  font-weight: $font-weight-light;
  font-family: 'Raleway';
}
.photo-repository-card__media {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  width: 100%;
  cursor: pointer;
}
.photo-repository-card-wrap .owl-carousel .owl-stage-outer {
  display: flex;
}
.photo-repository-card-wrap .owl-carousel .owl-stage {
    display: -webkit-box;
    display: -moz-box;
}
.photo-repository-card-wrap .owl-carousel .owl-item {
    width: max-content !important;
    cursor: pointer;
}
.photo-repository-card-wrap .owl-carousel .owl-item img {
   width: auto !important;
}
.photo-repository-card__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: $adaptive-mobile-small) {
  .photo-repository-card__image {
    background-size: cover;
  }
}