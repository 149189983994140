.spbgu-cookie-form .cookie-block {
  background-color: #F4F4F4;
  width: 100%;
  min-width: 20rem;
  position: fixed;
  left: auto;
  bottom: 0;
  z-index: 100;
  display: flex;
  padding: 28px 119px 28px 119px;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  justify-content: center;
}
.spbgu-cookie-form .cookie-block span {
  color: #1D1E25;
  display: inline-block;
  font-weight: 300;
  line-height: normal;
  font-size: 16px;
  font-family: 'Raleway';
}
.spbgu-cookie-form .cookie-block span .form__submit-bt {
  color: #FFF;
  font-family: 'Montserrat';
  font-size: 23px;
  font-weight: 600;
  background: #76767C;
  cursor: pointer;
  padding: 15px 40px 15px 40px;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius:0;
}
.spbgu-cookie-form .cookie-block span .form__submit-bt:hover {
  background: #CA0610;
}
@media (max-width: $adaptive-mobile) {
  .spbgu-cookie-form .cookie-block {
    padding: 18px 20px 18px 20px;
    gap: 5px;
  }
}
@media (max-width: $adaptive-mobile-small) {
  .spbgu-cookie-form .cookie-block {
    flex-direction: row;
    align-items: center;
  }
  .spbgu-cookie-form .cookie-block span {
    font-size: 10px;
  }
  .spbgu-cookie-form .cookie-block span .form__submit-bt {
    font-size: 16px;
    font-weight: 600;
    background: #76767C;
    cursor: pointer;
    padding: 6px 25px 7px 25px;
  }
}
@media (max-width: $adaptive-mobile-ultra-small) {
  .spbgu-cookie-form .cookie-block {
    flex-direction: column;
    align-items: flex-start;
  }
}