//-----Размеры шрифта------------------------------------------------------------------------------//
$font-small-sm: 8px;
$font-small-x: 9px;
$font-small: 12px;
$font-small-sx: 14px;
$font-small-s: 16px;
$font-base: 18px;
$font-base-s: 20px;
$font-base-m: 22px;
$font-base-sx: 24px;
$font-base-x: 26px;
$font-medium-sm: 28px;
$font-medium-s: 30px;
$font-medium: 32px;
$font-medium-x: 35px;
$font-special-s: 38px;
$font-special: 40px;
$font-special-x: 47px;
$font-large-sm: 60px;
$font-large: 70px;
$font-large-s: 87px;
$font-large-x: 90px;
//-----Ширина шрифта-------------------------------------------------------------------------------//
$font-weight-light: 300;
$font-weight-light-bold: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
//-----Цвета--------------------------------------------------------------------------------------//
$color-white: #FFFFFF;
$color-red: #CA0610;
$color-black: #1D1E25;
$color-gray: #BDBDBD;
$color-mint: #93CFBD;
$color-darkgray: #76767C;
$color-light-gray: #BDBDBD;
$color-smoke: #F4F4F4;
$color-light-black: #4a4a4a;
//-----Размеры экрана для адаптива(width)----------------------------------------------------------------//
$adaptive-large-device: 2150px;
$adaptive-high-device: 1780px;
$adaptive-first-device: 1441px;
$adaptive-special-device: 1410px;
$adaptive-second-device: 1441px;
$adaptive-ipad: 1025px;
$adaptive-mobile: 991px;
$adaptive-mobile-small: 767px;
$adaptive-mobile-small-xs: 450px;
$adaptive-mobile-ultra-small: 325px;
//-----Размеры экрана для адаптива(height)----------------------------------------------------------------//
$adaptive-mobile-height: 550px;




