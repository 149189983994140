//-----Aside---------------------------------------------------------------------------------------------//
.aside-block {
  position: fixed;
  //left: 0;
  width: 100vh;
  height: 50px;
  background-color: $color-white;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  bottom: -50px;
  transform: rotate(270deg);
  transform-origin: -2px 0;
  z-index: 45;
}
.sidebar-container-ul {
  list-style-type: none;
  padding: 0 155px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 27px;
}
.sidebar-container-li-item {
  color: $color-darkgray;
  font-weight: $font-weight-light;
  line-height: 103%;
  font-family: 'Raleway';
  font-size: $font-base-s;
}
.sidebar-item--active {
  color: $color-mint;
}
.sidebar-container-li-item:hover {
  color: $color-mint;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
//------Адаптивная версия-------------------------------------------------------------------------//
@media (min-height: $adaptive-mobile-height)  {
  .sidebar-container-ul {
    padding: 0 160px 0 0;
    gap: 47px;
  }
  .sidebar-container-li-item {
    font-size: $font-medium-sm;
  }
}
@media (max-width: $adaptive-second-device) {
  .sidebar-container-ul {
    padding: 0 120px 0 0;
  }
  .sidebar-container-li-item {
    font-size: $font-base-x;
  }
}
@media (max-width: $adaptive-mobile) {
  .aside-block {
    display: none;
  }
}