@charset "UTF-8";
//-----Базовые стили------------------------------------------------------------------------------//
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul,
ol,
li {
  list-style: none;
}

img {
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  margin: initial;
}

html,
body {
  height: 100%;
  line-height: 1;
  font-family: Montserrat, Arial;
  font-size: $font-base;
  -webkit-tap-highlight-color: transparent;
  max-width: 2050px;
  margin: 0 auto !important;
}

._active--body {
  overflow: hidden;
}
._container {
  max-width: 2050px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
}

._ibg {
  position: relative;
}

._ibg img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

button {
  overflow: visible;
  font: inherit;
  text-transform: none;
  border: none;
  border: 0;
  background-color: transparent;

  &:focus {
    outline: none;
  }
}
//------Адаптивная версия-------------------------------------------------------------------------//
@media (max-width: $adaptive-mobile) {
  ._container {
    padding: 0;
  }
}
