//-----Design-template---------------------------------------------------------------------------//
.design-template-wrap {
  display: flex;
  flex-direction: column;
}
.design-template-wrap > div:nth-child(2) {
  display: block;
}
.design-template-menu-title {
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-bottom: 70px;
}
.design-template__title {
  line-height: 103%;
  font-weight: $font-weight-semi-bold;
  font-size: $font-large;
  color: $color-black;
}
.design-template__menu {
  display: flex;
  flex-wrap: wrap;
  gap: 37px;
}
.design-template__menu-item {
  line-height: 103%;
  font-weight: $font-weight-semi-bold;
  font-size: $font-base-s;
  color: $color-light-gray;
  cursor: pointer;
}
.design-template-card--fonts {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.design-template__menu-item._active {
  color: $color-mint;
}
.design-template__menu-item:hover {
  color: $color-mint;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.design-template-download__bt {
  margin-top: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  max-width: 160px;
}
.design-template-download--icon {
  width: 30px;
  height: 30px;
  background-image: url("../../img/icons/DownLoadIcon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.design-template-download--link {
  font-weight: $font-weight-light;
  font-size: $font-medium-s;
  font-family: 'Raleway';
  color: $color-black;
  text-decoration: underline $color-black;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
}
.design-template-download__bt:hover {
  color: $color-mint;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  & .design-template-download--icon {
    background-image: url("../../img/icons/DownLoadIconActive.svg");
  }
  & .design-template-download--link {
    color: $color-mint;
    text-decoration: underline $color-mint;
  }
}
.design-template-card-wrap .owl-nav {
  display: none;
}
.design-template-card-wrap .owl-carousel .owl-dots {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 18px;
  margin-top: 37px;
}
.design-template-card-wrap .owl-dots .owl-dot {
  height: 17px;
  width: 17px;
  background-color: $color-gray;
}
.design-template-card-wrap .owl-dots .owl-dot.active {
  height: 17px;
  width: 17px;
  background-color: $color-mint;
}
.design-template-card__text {
  line-height: 133%;
  font-size: $font-base-x;
  color: $color-black;
}
@media (max-width: $adaptive-special-device) {
  .design-template__menu {
    gap: 24px;
  }
}
@media (max-width: $adaptive-second-device) {
  .design-template__title {
    font-size: $font-special-x;
  }
  .design-template__menu-item {
    font-size: $font-base;
  }
}
@media (max-width: $adaptive-mobile) {
  .design-template__menu {
    gap: 20px;
  }
  .design-template-menu-title  {
    margin-bottom: 30px;
  }
  .design-template-download__bt {
    margin-top: 30px;
  }
  .design-template-download--icon {
    width: 24px;
    height: 24px;
  }
  .design-template-download--link {
    font-size: $font-base-x;
  }
}
@media (max-width: $adaptive-mobile-small) {
  .design-template__menu {
    gap: 16px;
  }
  .design-template-download__bt {
    gap: 6px;
  }
  .design-template-download--icon {
    width: 14px;
    height: 14px;
  }
  .design-template-download--link {
    font-size: $font-small-s;
  }
  .design-template-card__text {
    font-size: $font-small-s;
  }
}
@media (max-width: $adaptive-mobile-small-xs) {
  .design-template__title {
    font-size: $font-medium-sm;
  }
  .design-template__menu-item {
    font-size: $font-small;
  }
  .design-template-card-wrap .owl-carousel .owl-dots {
    margin-top: 25px;
    gap: 10px;
  }
  .design-template-card-wrap .owl-dots .owl-dot {
    height: 10px;
    width: 10px;
  }
  .design-template-card-wrap .owl-dots .owl-dot.active {
    height: 10px;
    width: 10px;
  }
}
//-----Design-template-card---------------------------------------------------------------------------//
.design-template-card-wrap {
  display: none;
}
.design-template-card-wrap > div:last-child {
  margin-bottom: 0;
}
.design-template-card--color-gray {
  background-color: $color-darkgray;
}
.design-template-card--color-light-gray {
  background-color: $color-light-gray;
}
.design-template-card--color-red {
  background-color: $color-red;
}
.design-template-card--color-light-black {
  background-color: $color-light-black;
}
.design-template-card--color-mint {
  background-color: $color-mint;
}
.design-template-card__color-block {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 36px;
  padding: 50px 50px;
}
.design-template-card__font {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.design-template-card__block-text {
  font-size: $font-medium-s;
  color: $color-white;
  font-weight: $font-weight-light;
  font-family: 'Raleway';
}
@media (max-width: $adaptive-second-device) {
  .design-template-card__font {
    gap: 6px;
  }
}
@media (max-width: $adaptive-ipad) {
  .design-template-card__font {
    gap: 18px;
    flex-direction: column;
  }
  .design-template-card__color-block {
    padding: 20px 20px;
    gap: 18px;
  }
}
@media (max-width: $adaptive-mobile-small) {
  .design-template-card__block-text {
    font-size: $font-base-sx;
  }
  .design-template-card-wrap .owl-dots .owl-dot {
    width: 14px;
    height: 14px;
  }
  .design-template-card-wrap .owl-dots .owl-dot.active {
    width: 14px;
    height: 14px;
  }
}
@media (max-width: $adaptive-mobile-small-xs) {
  .design-template-card__block-text {
    font-size: $font-small-x;
  }
}